import React, { useState } from 'react';
import Select from 'react-select';
import { Add } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import Button from '@truescope-web/react/lib/components/form/Button';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { getWorkspaces } from '../Scopes/ScopeConstants';
import { scopeTableFields } from './MediaItemConstants';

const ScopeOptions = ({ selectOptions, handleOnScopeSave }) => {
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const [scope, setScope] = useState();
	const [scopeValue, setScopeValue] = useState(null);
	const [enableSaveButton, setEnableSaveButton] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showOptions, setShowOptions] = useState(false);

	const handleShowOptions = () => {
		setShowOptions(!showOptions);
	};

	const getWorkspacesData = async (scope_id) => {
		try {
			const { workspaces: workspaceData, message } = await getWorkspaces(getDatahubApi, scope_id);

			if (!stringIsNullOrEmpty(message)) {
				showSnackbar(message, snackbarVariants.error);
			}

			return (workspaceData || []).map((workspace) => ({ ...workspace, checked: true }));
		} catch (e) {
			showSnackbar(e.message, snackbarVariants.error);
		}
	};

	const handleOnWorkSpaceChange = (checkedValue, index) => {
		const workspacesData = [...scope.workspaces];
		workspacesData[index].checked = checkedValue;
		setScope((prev) => ({ ...prev, workspaces: workspacesData }));
	};

	const handleChangeScope = (option) => {
		const scope = { name: option.label, scope_id: option.metadata.scope_id, score: 1 };

		setIsLoading(true);
		setEnableSaveButton(false);
		setScopeValue(option);

		getWorkspacesData(scope.scope_id)
			.then((workspaces) => {
				Object.assign(scope, { workspaces: [...workspaces.map((workspace) => ({ ...workspace, checked: true }))] });
			})
			.catch((e) => {
				const message = `Error loading workspaces attached to ${option.label}`;
				showSnackbar(message, snackbarVariants.error);
				console.error(message, e);
			})
			.finally(() => {
				setScope(scope);
				setEnableSaveButton(true);
				setIsLoading(false);
			});
	};

	const handleOnSave = () => {
		handleOnScopeSave(scope);
		handleCancel();
		setShowOptions(false);
	};

	const handleCancel = () => {
		setScopeValue(null);
		setScope(null);
		setEnableSaveButton(false);
		setShowOptions(false);
	};

	return (
		<div className="scope-options">
			{!showOptions ? (
				<Button tooltip="Add" fab={<Add />} onClick={handleShowOptions}></Button>
			) : (
				<div className="panel">
					<Select
						className="select"
						options={selectOptions}
						placeholder={`Add a ${scopeTableFields[0].label}`}
						autoFocus
						menuPosition="fixed"
						fullWidth
						value={scopeValue}
						onChange={handleChangeScope}
						isLoading={isLoading}
					/>
					{!arrayIsNullOrEmpty(scope?.workspaces) && (
						<div className="workspaces-panel">
							<Typography variant="subtitle">
								The selected feed is attached to the following Workspaces. Also add this item to checked Workspaces.
							</Typography>
							<WorkspaceList workspaces={scope.workspaces} handleOnWorkSpaceChange={handleOnWorkSpaceChange} />
						</div>
					)}
					<Inline horizontalAlignment={horizontalAlignment.right} className="buttons">
						<Button className="cancel" variant="contained" onClick={handleCancel}>
							Cancel
						</Button>
						<Button
							className="save"
							variant={!enableSaveButton ? 'contained' : 'primary'}
							disabled={!enableSaveButton}
							onClick={handleOnSave}
						>
							Save
						</Button>
					</Inline>
				</div>
			)}
		</div>
	);
};

const WorkspaceList = ({ workspaces, handleOnWorkSpaceChange }) =>
	workspaces?.map((workspace, index) => (
		<Inline responsiveWrap={false} key={`${workspace.workspace_id}-${workspace.workspace_scope_id}`}>
			<div>
				<Checkbox
					color="primary"
					className="checkbox__control"
					checked={workspace.checked}
					onChange={(_event, checkedValue) => handleOnWorkSpaceChange(checkedValue, index)}
				/>
			</div>
			<div>{workspace.name}</div>
		</Inline>
	));

export default ScopeOptions;
