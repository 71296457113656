import { handleError } from '../../../../../components/Api';

export const removeWorkspaceContentRight = (getDatahubApi, workspaceId, workspaceContentRightId) => {
	return getDatahubApi().then((api) =>
		api
			.delete(`/workspaces/v1/content-rights/${workspaceId}/${workspaceContentRightId}`)
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to remove workspace content right`, e))
	);
};

export const addWorkspaceContentRight = (getDatahubApi, workspaceId, contentRightsId) => {
	return getDatahubApi().then((api) =>
		api
			.put(`/workspaces/v1/content-rights`, { workspaceId, contentRightsId })
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to remove workspace content right`, e))
	);
};
