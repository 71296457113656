import Select from '@truescope-web/react/lib/components/form/Select';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Condition from './Condition';
import OperatorButton from './OperatorButton';
import { operatorOptions } from './QueryConditionGroupsBuilderConstants';

const ConditionGroup = ({ conditionGroup, onChange, onChangeGroupOperator, onDelete, fieldOptions, showGroupOperator = true }) => {
	const handleConditionsOperatorChange = (_e, newConditionsOperator) => {
		onChange({
			...conditionGroup,
			conditionsOperator: newConditionsOperator
		});
	};

	const handleChangeCondition = (updatedCondition, index) => {
		const updatedConditionGroup = { ...conditionGroup };
		updatedConditionGroup.conditions[index] = updatedCondition;
		onChange(updatedConditionGroup);
	};

	const handleDeleteCondition = (index) => {
		if (conditionGroup.conditions.length === 1) {
			onDelete();
		} else {
			const updatedConditionGroup = { ...conditionGroup };
			updatedConditionGroup.conditions.splice(index, 1);
			onChange(updatedConditionGroup);
		}
	};

	const handleAddCondition = () => {
		const updatedConditionGroup = { ...conditionGroup };
		updatedConditionGroup.conditions.push({
			id: Math.max(...updatedConditionGroup.conditions.map((condition) => condition.id)) + 1,
			field: null,
			value: null,
			filterFunction: null
		});
		onChange(updatedConditionGroup);
	};

	return (
		<div className="condition-group">
			{showGroupOperator && (
				<div className="condition-group__group-operator">
					<OperatorButton value={conditionGroup.groupOperator} onChange={onChangeGroupOperator} />
				</div>
			)}
			<div className="condition-group__conditions">
				<div className="condition-group__conditions__label">Filter Group</div>
				<Grid container spacing={2}>
					{conditionGroup.conditions.length > 1 && (
						<Grid item>
							<Inline responsiveWrap={false}>
								<span>Include</span>
								<div className="condition-group__conditions__include">
									<Select
										options={operatorOptions}
										value={conditionGroup.conditionsOperator}
										onChange={handleConditionsOperatorChange}
										fullWidth={false}
									/>
								</div>
								<span>of these conditions</span>
							</Inline>
						</Grid>
					)}
					{conditionGroup.conditions.map((condition, index) => (
						<Grid item key={condition.id}>
							<Condition
								condition={condition}
								operator={conditionGroup.conditionsOperator}
								fieldOptions={fieldOptions}
								onChange={(updatedCondition) => handleChangeCondition(updatedCondition, index)}
								onDelete={() => handleDeleteCondition(index)}
								onAdd={index === conditionGroup.conditions.length - 1 ? handleAddCondition : undefined}
							/>
						</Grid>
					))}
				</Grid>
				<div className="condition-group__conditions__container"></div>
			</div>
		</div>
	);
};

export default ConditionGroup;
