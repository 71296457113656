import Button, { ButtonGroup } from '@truescope-web/react/lib/components/form/Button';

const OperatorButton = ({ value, onChange }) => {
	return (
		<ButtonGroup>
			<Button variant={value === 'must' ? 'primary' : 'default'} onClick={() => onChange('must')}>
				AND
			</Button>
			<Button variant={value === 'should' ? 'primary' : 'default'} onClick={() => onChange('should')}>
				OR
			</Button>
			<Button variant={value === 'must_not' ? 'primary' : 'default'} onClick={() => onChange('must_not')}>
				NOT
			</Button>
		</ButtonGroup>
	);
};

export default OperatorButton;
