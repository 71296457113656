import { authStorageKey } from './constants';

/**
 * resets the authentication state to empty and loading = true
 */
export const resetAuthenticationState = () => {
	return {
		type: 'resetAuthenticationState'
	};
};

/**
 * sets isLoading
 * @returns {boolean} isLoading
 */
export const setIsLoading = (isLoading) => {
	return {
		type: 'setIsLoading',
		payload: { isLoading }
	};
};

/**
 * sets the error message
 * @returns {string} errorMessage
 */
export const setErrorMessage = (errorMessage) => {
	return {
		type: 'setErrorMessage',
		payload: { errorMessage }
	};
};

/**
 * updates the current token
 * @param {string} refreshToken refreshToken
 * @param {string} accessToken accessToken
 * @param {number} expiry expirey in seconds
 * @returns {object} state
 */
export const updateToken = (refreshToken, accessToken, expiry, user) => {
	return {
		type: 'updateToken',
		payload: { refreshToken, accessToken, expiry, user }
	};
};

/**
 * sets the current token
 * @param {string} refreshToken refreshToken
 * @param {string} accessToken accessToken
 * @param {number} expiry expirey in seconds
 * @param {object} user user
 * @returns {object} state
 */
export const setAuthenticationState = (refreshToken, accessToken, expiry, user) => {
	return {
		type: 'setAuthenticationState',
		payload: { refreshToken, accessToken, expiry, user }
	};
};

/**
 * clears the current refreshToken
 * @returns {object} state
 */
export const clearAuthenticationState = () => {
	return {
		type: 'clearAuthenticationState'
	};
};

/**
 * gets the initial state of the reducer
 * also tries to load the currently persisted token
 * @returns {object} state
 */
export const getInitialState = (defaultState) => {
	return {
		isAuthenticated: false,
		isLoading: false,
		errorMessage: null,
		...(defaultState || {})
	};
};

const googleAuthReducer = (state, action) => {
	switch (action?.type) {
		case 'setErrorMessage': {
			const { errorMessage } = action.payload;
			return {
				...state,
				errorMessage
			};
		}
		case 'setIsLoading': {
			const { isLoading } = action.payload;
			return {
				...state,
				isLoading: isLoading
			};
		}
		case 'setAuthenticationState': {
			const { refreshToken, accessToken, expiry, user } = action.payload;
			localStorage.setItem(authStorageKey, JSON.stringify({ refreshToken, accessToken, expiry, user }));
			//we dont store the tokens in local memory, because if you have multiple tabs open, you could end up with different tokens!
			//so we access the token directly in local storage
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				errorMessage: null
			};
		}
		case 'updateToken': {
			const { refreshToken, accessToken, expiry, user } = action.payload;
			localStorage.setItem(authStorageKey, JSON.stringify({ refreshToken, accessToken, expiry, user }));
			return {
				...state,
				isAuthenticated: true,
				errorMessage: null,
				isLoading: false
			};
		}
		case 'resetAuthenticationState': {
			return {
				...state,
				isLoading: true,
				isAuthenticated: false,
				errorMessage: null
			};
		}
		case 'clearAuthenticationState': {
			localStorage.removeItem(authStorageKey);
			return {
				...state,
				isAuthenticated: false,
				isLoading: false
			};
		}
		case 'reset':
			return getInitialState();
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export default googleAuthReducer;
