import { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@truescope-web/react/lib/components/form/Select';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { findOption } from './QueryConditionGroupsBuilderConstants';
import ValueEditor from './ValueEditor';

const getDefaultFilterFunction = (functionOptions) => {
	if (arrayIsNullOrEmpty(functionOptions)) {
		return null;
	}

	if (functionOptions.length === 1) {
		return functionOptions[0].value;
	}

	//we prefer terms if possible
	if (functionOptions.some(({ value }) => value === 'terms')) {
		return 'terms';
	}

	return null;
};

const Condition = ({ condition, operator, fieldOptions, onChange, onDelete, onAdd }) => {
	const functionOptions = useMemo(() => {
		if (stringIsNullOrEmpty(condition.field)) {
			return null;
		}
		return findOption(fieldOptions, condition.field)?.functionOptions;
	}, [condition?.field, fieldOptions]);

	const handleValueChange = (newValue) => {
		onChange({
			...condition,
			value: newValue
		});
	};

	const handleFieldChange = (_e, _value, rawValue) => {
		onChange({
			...condition,
			field: rawValue.value,
			filterFunction: getDefaultFilterFunction(rawValue?.functionOptions),
			value: null
		});
	};

	const handleFunctionChange = (_e, newFilterFunction) => {
		onChange({
			...condition,
			filterFunction: newFilterFunction,
			value: null
		});
	};

	return (
		<div className="condition">
			<div className="condition__field">
				<Select
					options={fieldOptions}
					value={condition.field}
					groupBy="group"
					label="Field"
					placeholder="Select a field to filter on"
					onChange={handleFieldChange}
				/>
			</div>
			<div className="condition__filter-function">
				{!isNullOrUndefined(functionOptions) ? (
					<Select
						options={functionOptions}
						value={condition.filterFunction}
						label="Function"
						placeholder="Select a function type"
						onChange={handleFunctionChange}
					/>
				) : (
					<Typography variant="subtitle">Select a field</Typography>
				)}
			</div>
			<div className="condition__value">
				{!isNullOrUndefined(condition.filterFunction) ? (
					<ValueEditor
						value={condition.value}
						onChange={handleValueChange}
						filterFunction={condition.filterFunction}
						field={condition.field}
					/>
				) : (
					<Typography variant="subtitle">Select a function</Typography>
				)}
			</div>
			<div className="condition__tools">
				{isNullOrUndefined(onAdd) ? (
					<div className="condition__tools__operator">{operator === 'must' ? 'AND' : 'OR'}</div>
				) : (
					<ToolbarButton className="condition__tools__add-condition" title="Add" icon={<AddIcon />} onClick={onAdd} size="md" />
				)}
				<div className="condition__tools__delete">
					<ToolbarButton title="Remove" icon={<CloseIcon />} onClick={onDelete} size="md" />
				</div>
			</div>
		</div>
	);
};

export default Condition;
