import DataGrid, { Column, FilterRow, HeaderFilter, RemoteOperations, Scrolling } from 'devextreme-react/data-grid';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { convertItems, createDataSource } from '../../../components/Dx/DxGridConstants';
import Header from '../../../components/Header';
import { useDataGridHeight } from '../../../hooks/useWindowHeight';
import Author from './Author';
import { updateAuthor } from './AuthorConstants';

const Authors = () => {
	const [getDatahubApi] = useApiLookup();
	const [showAuthorSheet, setShowAuthorSheet] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selected, setSelected] = useState(null);
	const [showDelete, setShowDelete] = useState(false);
	const [dataSource, setDataSource] = useState(() =>
		createDataSource({
			getDatahubApi,
			requestPath: '/authors/v1',
			convertItems,
			useWhereClauses: true
		})
	);

	const dataGrid = useRef(null);
	const history = useHistory();
	const gridHeight = useDataGridHeight();

	const handleAddToSearch = () => {
		history.push({
			pathname: '/media/search',
			state: { queryText: `author:"${selected.name}"` }
		});
	};

	const handleCloseMenu = (_e) => {
		setAnchorEl(null);
	};

	const handleOpenMenu = (e, selected) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setSelected(selected);
	};

	const handlePromptDelete = () => {
		handleCloseMenu();
		setShowDelete(true);
	};

	const handleCancelDelete = () => {
		setShowDelete(false);
		setSelected(null);
	};

	const handleAcceptDelete = () => {
		dataGrid.current._instance.beginCustomLoading();
		const author = selected;
		setShowDelete(false);
		setSelected(null);
		updateAuthor(getDatahubApi, author, 'ignore', true).then(() => {
			dataGrid.current._instance.endCustomLoading();
			setDataSource(
				createDataSource({
					getDatahubApi,
					requestPath: '/authors/v1',
					convertItems,
					useWhereClauses: true
				})
			);
		});
	};

	const handleEdit = () => {
		setShowAuthorSheet(true);
		setAnchorEl(null);
	};

	const handleCellClick = useCallback(
		({ data, column }) => {
			if (isNullOrUndefined(data) || column.ignoreCellClick === true) {
				return;
			}
			setSelected(data);
			setShowAuthorSheet(true);
		},
		[setSelected, setShowAuthorSheet]
	);

	const handleCloseAuthorSheet = useCallback(() => {
		setShowAuthorSheet(false);
		setSelected(null);
		setDataSource(
			createDataSource({
				getDatahubApi,
				requestPath: '/authors/v1',
				convertItems,
				useWhereClauses: true
			})
		);
	}, [setShowAuthorSheet, setSelected, setDataSource]);

	const renderNameCell = ({ _data, value }) => {
		return <div className="wrap">{value}</div>;
	};

	const renderMenu = () => {
		return (
			<Menu id="row-menu" anchorEl={anchorEl} keepMounted open={!isNullOrUndefined(anchorEl)} onClose={handleCloseMenu}>
				<MenuItem onClick={handleAddToSearch}>
					<Typography>Add to Search</Typography>
				</MenuItem>
				<MenuItem onClick={handleEdit}>
					<Typography>Edit</Typography>
				</MenuItem>
				<MenuItem onClick={handlePromptDelete}>
					<Typography>Remove</Typography>
				</MenuItem>
			</Menu>
		);
	};

	const renderOptionsCell = ({ data }) => {
		return (
			<div className="wrap">
				<IconButton aria-controls="row-menu" aria-haspopup="true" onClick={(e) => handleOpenMenu(e, data)}>
					<MoreVert />
				</IconButton>
			</div>
		);
	};

	const renderAuthorSheet = () => {
		return (
			<Sheet isOpen={showAuthorSheet} onClose={handleCloseAuthorSheet}>
				<div className="sheet-content">
					<Author author_id={selected?.author_id} />
				</div>
			</Sheet>
		);
	};

	const renderGrid = () => {
		return (
			<DataGrid
				id="authors-table"
				dataSource={dataSource}
				showRowLines
				hoverStateEnabled
				showColumnLines={false}
				onCellClick={handleCellClick}
				ref={dataGrid}
				showBorders
				height={gridHeight}
				remoteOperations
				wordWrapEnabled
			>
				<Scrolling mode="infinite" />

				<FilterRow visible applyFilter="auto" />

				<HeaderFilter visible />

				<Column
					caption="Author"
					dataField="name"
					cssClass="cell cell--pad clickable"
					cellRender={renderNameCell}
					allowSearch
					allowFiltering
					allowHeaderFiltering={false}
				>
					<HeaderFilter />
				</Column>

				<Column
					dataField="id"
					width={60}
					cssClass="cell cell--center"
					alignment="center"
					caption=""
					cellRender={renderOptionsCell}
					allowFiltering={false}
					allowSearch={false}
					allowSorting={false}
					ignoreCellClick
				/>

				<RemoteOperations sorting filtering />
			</DataGrid>
		);
	};

	return (
		<>
			<Content>
				<Header header="Authors" />
				<Grid container>
					<Grid item>{renderGrid()}</Grid>
				</Grid>
				<Alert
					title="Delete Author"
					message="Are you sure you want to delete this author?"
					cancelLabel="No"
					acceptLabel="Yes"
					open={showDelete}
					handleCancel={handleCancelDelete}
					handleAccept={handleAcceptDelete}
				/>
			</Content>
			{renderMenu()}
			{renderAuthorSheet()}
		</>
	);
};

export default Authors;
