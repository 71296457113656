import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LockOpen from '@mui/icons-material/LockOpen';
import Save from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import Button from '@truescope-web/react/lib/components/form/Button';
import Select from '@truescope-web/react/lib/components/form/Select';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Paper from '@truescope-web/react/lib/components/layout/Paper';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { validateObject, validationResults } from '@truescope-web/react/lib/utils/validation';
import { validateEmail } from '@truescope-web/react/lib/utils/validationFunctions';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../components/Api';
import { useApiLookup } from '../../components/ApiLookupProvider';
import Header from '../../components/Header';
import { createUserData, departmentOptions, saveUser } from '../../components/Widgets/User/UserConstants';
import UserImage from '../../components/Widgets/User/UserImage';
import UserInfo from '../../components/Widgets/User/UserInfo';
import { resetClientUserPassword } from './ClientUserConstants';
import ClientUserWorkspacesTable from './ClientUserWorkspacesTable';

const clientUserValidationRules = {
	name: ({ name }) => (stringIsNullOrEmpty(name?.trim()) ? validationResults.required() : validationResults.ok()),
	nickname: ({ nickname }) => (stringIsNullOrEmpty(nickname?.trim()) ? validationResults.required() : validationResults.ok()),
	email: ({ email }) => validateEmail(email, true)
};

const ClientUser = ({ userId: uid, onSave, onClose }) => {
	const match = useRouteMatch();
	const userId = !stringIsNullOrEmpty(uid) ? uid : match.params.user_id;
	const [user, setUser] = useState(null);
	const [profilePic, setProfilePic] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isPasswordButtonLoading, setIsPasswordButtonLoading] = useState(false);
	const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
	const [validationState, setValidationState] = useState();
	const originalUserData = useRef(null);
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();

	const handleGetUser = useCallback(async () => {
		try {
			const { initialPictureData, picture, ...userData } = await createUserData(getDatahubApi, userId, 'client-users');
			setUser(userData);
			originalUserData.current = userData;
			setProfilePic({ initialPictureData: initialPictureData, picture: picture });
		} catch (e) {
			setErrorMessage({ header: `Failed to find user`, error: `${e.message}` });
		}
	}, [setProfilePic, setUser, setErrorMessage, getDatahubApi, userId]);

	useEffect(() => {
		handleGetUser();
	}, [handleGetUser]);

	const handleChange = (event) => {
		setUser((prev) => ({ ...prev, [event.target.id]: event.target.value }));
	};

	const handleDepartmentChange = (_event, _value, rawValue) => {
		setUser((prev) => ({ ...prev, department: rawValue.value }));
	};

	const handleResetPassword = async () => {
		setIsPasswordButtonLoading(true);
		try {
			await resetClientUserPassword(user.email);
			showSnackbar(`Password Reset Sent`, snackbarVariants.success);
		} catch (e) {
			const msg = `Failed to issue password reset ${extractError(e)}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
		} finally {
			setIsPasswordButtonLoading(false);
		}
	};

	const handleSave = async () => {
		setIsSaveButtonLoading(true);
		try {
			const newValidationState = validateObject(user, clientUserValidationRules);
			setValidationState(newValidationState);
			if (!newValidationState.success) {
				return;
			}

			await saveUser(getDatahubApi, user, profilePic, `/client-users/v1/${userId}`);
			const { picture, pictureData, initialPictureData } = profilePic;
			if (isNullOrUndefined(pictureData) && initialPictureData !== pictureData) {
				setProfilePic({ picture: picture, initialPictureData: pictureData });
			} else {
				setProfilePic({ picture: picture, initialPictureData: initialPictureData });
			}
			if (!isNullOrUndefined(onSave)) {
				onSave();
			}
			showSnackbar(`User details updated`, snackbarVariants.success);
			if (!isNullOrUndefined(onClose)) {
				onClose();
			}
		} catch (e) {
			const errorMessage = extractError(e);
			setErrorMessage({ header: `Failed to save user`, error: errorMessage });
			showSnackbar(errorMessage, snackbarVariants.error);
		} finally {
			setIsSaveButtonLoading(false);
		}
	};

	const handleSaveProfilePicture = async (userPictureData) => {
		try {
			const { data } = await saveUser(
				getDatahubApi,
				originalUserData.current,
				{ pictureData: userPictureData },
				`/client-users/v1/${userId}`
			);
			setUser((prev) => ({
				...prev,
				picture: data.user.picture
			}));
			setProfilePic({ picture: data.user.picture, initialPictureData: userPictureData });
			showSnackbar(`User's profile picture updated`, snackbarVariants.success);
			return { fileUrl: profilePic.picture };
		} catch (e) {
			setErrorMessage({ header: `Failed to save user profile picture`, error: `${e.message}` });
			showSnackbar(`Failed to update user profile picture`, snackbarVariants.error);
			return { message: 'Failed to update user profile picture' };
		}
	};

	const renderWorkspaces = () => {
		if (arrayIsNullOrEmpty(user?.workspaces)) {
			return (
				<Grid item>
					<Inline>
						<Typography variant="h4">No workspaces</Typography>
					</Inline>
				</Grid>
			);
		}

		return (
			<>
				<Grid item>
					<Typography variant="h4">Workspaces</Typography>
				</Grid>
				<Grid item>
					<ClientUserWorkspacesTable user={user} setUser={setUser} />
				</Grid>
			</>
		);
	};

	const renderUser = () => {
		return (
			<>
				<Grid item md={8}>
					<Grid container>
						<Grid item>
							<Typography variant="h4">Details</Typography>
						</Grid>
						<UserInfo showId setUser={setUser} user={user} validationState={validationState} />
						<Grid item xs={6}>
							<TextField label="Email" id="email" value={user?.email || ''} onChange={handleChange} />
							<ValidationPlaceholder validationResult={validationState?.results?.email} />
						</Grid>
						<Grid item xs={6}>
							<TextField id="mobile" label="Mobile" value={user?.mobile || ''} onChange={handleChange} />
						</Grid>
						<Grid item>
							<Divider />
						</Grid>
						<Grid item>
							<Inline>
								<TextField
									id="organisation"
									label="Organisation"
									value={user?.organisation || ''}
									onChange={handleChange}
								/>
							</Inline>
						</Grid>
						<Grid item>
							<Inline>
								<TextField id="job_title" label="Job Title" value={user?.job_title || ''} onChange={handleChange} />
								<Select
									label="Department"
									value={user?.department || ''}
									options={departmentOptions}
									placeholder="Enter your department"
									onChange={handleDepartmentChange}
								/>
							</Inline>
						</Grid>
						<Grid item>
							<Divider />
						</Grid>
						<Grid item>
							<Inline>
								<TextField label="Profile Image Url" value={profilePic?.picture || ''} disabled />
							</Inline>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={4}>
					<UserImage onChange={setProfilePic} picture={profilePic?.picture || ''} onUpload={handleSaveProfilePicture} />
				</Grid>
				<Grid item>
					<Divider />
				</Grid>
				{renderWorkspaces()}
				<Grid item>
					<Inline horizontalAlignment={horizontalAlignment.right}>
						<Button onClick={handleResetPassword} startIcon={<LockOpen />} loading={isPasswordButtonLoading}>
							Reset Password
						</Button>
						<Button variant="confirm" onClick={handleSave} startIcon={<Save />} loading={isSaveButtonLoading}>
							Save
						</Button>
					</Inline>
				</Grid>
			</>
		);
	};

	const renderBody = () => {
		if (isNullOrUndefined(user)) {
			return (
				<Grid item>
					<SkeletonWrapper />
				</Grid>
			);
		}

		if (!isNullOrUndefined(user.message)) {
			return <Grid item>{user.message}</Grid>;
		}

		return renderUser();
	};

	return (
		<Content>
			<>
				<Header header={user?.name || ''} />
				<Grid container>
					<Grid item>
						<Paper>
							<Grid container>{renderBody()}</Grid>
						</Paper>
					</Grid>
					{!isNullOrUndefined(errorMessage) && (
						<Grid item>
							<h2>{errorMessage.header}</h2>
							{errorMessage.error}
						</Grid>
					)}
				</Grid>
			</>
		</Content>
	);
};

export default ClientUser;
