export const getInitialScopeState = (props = {}) => ({
	isInitialized: false,
	name: 'New Scope',
	scope_id: null,
	enable_item_matching: true,
	parent_scope_id: null,
	enable_ingest_backfill: false,
	date_updated: new Date().toJSON(),
	updated_by: null,
	elasticsearch_query: null,
	queryConditionGroups: [],
	...props
});

export const scopeReducer = (state, action) => {
	switch (action.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'initialize':
			return getInitialScopeState({ ...action.payload, isInitialized: true });
		case 'reset':
			return getInitialScopeState({ ...action.payload, isInitialized: false });
		default:
			throw new Error(`unknown reducer action type '${action.type}'`);
	}
};

export const updateScopeState = (data = {}) => ({
	type: 'update',
	payload: data
});

export const initializeScopeState = (data = {}) => ({
	type: 'initialize',
	payload: data
});
