export const reorderScopes = (scopes, startIndex, endIndex) => {
	const result = Array.from(scopes);

	// Set new sort order
	result[startIndex].sort_order = endIndex + 1;

	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};
