import React from 'react';
import { CircularProgress, Link } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Toolbar from '@mui/material/Toolbar';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { useEntities } from './provider';

export default function ToolBar({
	onSelectAllClick,
	handleAddParentClick,
	handleHideEntitiesClick,
	handleShowEntitiesClick,
	showHide = false
}) {
	const { totalCount, entities, selectedEntities, isLoadingHideShow } = useEntities();

	if (arrayIsNullOrEmpty(entities)) {
		return <></>;
	}

	const numSelected = selectedEntities.length;
	const totalLoaded = entities.length;

	const showHideLink = () => {
		if (numSelected > 0) {
			if (isLoadingHideShow) {
				return (
					<div>
						<CircularProgress size={16} />
					</div>
				);
			}

			return (
				<div>
					{showHide ? <Link onClick={handleHideEntitiesClick}>Hide</Link> : <Link onClick={handleShowEntitiesClick}>Show</Link>}
				</div>
			);
		}
	};

	return (
		<Toolbar disableGutters className="entities-toolbar" variant="dense">
			<div>
				<Inline>
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < totalLoaded}
						checked={totalLoaded > 0 && numSelected === totalLoaded}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
					/>
					<Typography component="div">
						<b>{totalCount} entities</b>
						{numSelected > 0 && ` / ${numSelected} selected`}
					</Typography>
				</Inline>
			</div>
			{numSelected > 0 && (
				<div>
					<Link onClick={handleAddParentClick}>Add Parent</Link>
				</div>
			)}
			{showHideLink()}
		</Toolbar>
	);
}
