import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@truescope-web/react/lib/components/form/Button';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import useCancelToken from '@truescope-web/react/lib/hooks/useCancelToken';
import { breakpointDown } from '@truescope-web/react/lib/utils/breakpoints';
import { stringFilterComparisonType, stringFilterComparisonTypeOptions } from '@truescope-web/utils/lib/filters';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import '../../../scss/views/Entities/EntitiesList/ParentDialog.scss';
import { addParent, searchEntities } from './constants';
import { useEntities } from './provider';

function ParentDialog() {
	const { showSnackbar } = useSnackbar();
	const [getDatahubApi] = useApiLookup();
	const { entities, entityTypes, selectedEntities, openParentDialog, setOpenParentDialog, handleSearch, setIsLoading, resetState } =
		useEntities();
	const [selectOptions, setSelectOptions] = useState();
	const [parentEntity, setParentEntity] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [filterType, setFilterType] = useState(
		stringFilterComparisonTypeOptions.find((option) => option.value === stringFilterComparisonType.startsWith)
	);
	const [resetToken] = useCancelToken();

	const handleSearchOptions = useCallback(
		debounce(async (input, callback) => {
			const { items = [] } = await searchEntities(
				getDatahubApi,
				input,
				filterType?.value,
				entityTypes[0].value,
				0,
				100,
				'name',
				'asc',
				resetToken
			);

			callback(items.map((item) => ({ label: item.name, value: item.id })));
		}, 300),
		[getDatahubApi, filterType?.value, entityTypes[0].value, resetToken]
	);

	useEffect(() => {
		if (!openParentDialog) {
			return;
		}

		setSelectOptions(
			(entities || []).filter(({ id }) => !selectedEntities.includes(id)).map((item) => ({ label: item.name, value: item.id }))
		);
	}, [openParentDialog, entities, selectedEntities]);

	const onChange = (option) => {
		setParentEntity(option);
	};

	const onFilterTypeChange = (option) => {
		setFilterType(option);
	};

	const handleClose = (reloadSearch = false) => {
		if (isSaving) {
			return;
		}

		setOpenParentDialog(false);
		setParentEntity();

		if (reloadSearch) {
			resetState();
			setIsLoading(true);
			handleSearch().then(() => setIsLoading(false));
		}
	};

	const handleSave = () => {
		setIsSaving(true);
		addParent(getDatahubApi, parentEntity.value, selectedEntities)
			.then(() => handleClose(true))
			.catch(() => {
				showSnackbar(`We're unable to add a parent to those entities, please try again.`, snackbarVariants.error);
			})
			.finally(() => setIsSaving(false));
	};

	return (
		<Dialog id="parent-dialog" fullScreen={breakpointDown('sm')} open={openParentDialog} onClose={() => handleClose(false)}>
			<DialogContent>
				<Grid container>
					<Grid item>
						<Typography variant="h1" bold>
							Add Parent
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle">
							Select a parent for the {selectedEntities.length} selected entities. The selected entities will be marked as
							aliases of the chosen parent. They will no longer show in the app or Datahub as separate entities and any
							mentions will be counted towards the chosen parent.
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Select
							className="select"
							options={stringFilterComparisonTypeOptions}
							value={filterType}
							onChange={onFilterTypeChange}
							placeholder="Choose a way to search"
							menuPosition="fixed"
						/>
					</Grid>
					<Grid item xs={8}>
						<AsyncSelect
							fullWidth
							className="select"
							defaultOptions={selectOptions}
							loadOptions={handleSearchOptions}
							onChange={onChange}
							value={parentEntity}
							placeholder="Choose a parent entity"
							menuPosition="fixed"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(false)} variant="default" size="medium" className="cancel" disabled={isSaving}>
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					variant="primary"
					size="medium"
					className={`save ${isSaving && 'is-saving'}`}
					disabled={isSaving || isNullOrUndefined(parentEntity)}
				>
					{isSaving ? <CircularProgress size={20} color="inherit" /> : 'Save'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ParentDialog;
