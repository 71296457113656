import React from 'react';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { lockAdornment } from '../constants';

const Market = ({ clientMarket }) => {
	return (
		<Grid container>
			<Grid item sm={6} xs={12}>
				<TextField
					labelAbove
					label="Market"
					value={!isNullOrUndefined(clientMarket) ? clientMarket.name : ''}
					disabled
					adornment={lockAdornment}
				/>
			</Grid>
		</Grid>
	);
};

export default Market;
