import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import TablePanel from '@truescope-web/react/lib/components/layout/TablePanel';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import DebugView from '@truescope-web/react/lib/components/widgets/DebugView';
import { sourceReferenceTypes } from '@truescope-web/utils/lib/entityHelpers';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import EntityAccordion from '../../../components/Widgets/EntityAccordion';
import SocialNetworkHandleEditor from '../../../components/Widgets/SocialNetworkHandleEditor';
import { createPersonSectionFields, getPerson, updatePerson } from './PeopleConstants';

const Person = ({ person_id: aid }) => {
	const match = useRouteMatch();
	const [getDatahubApi] = useApiLookup();
	const person_id = !stringIsNullOrEmpty(aid) ? aid : match.params.person_id;
	const [sectionFields, setSectionFields] = useState(null);
	const [person, setPerson] = useState(null);
	const [metadata, onChange] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleUpdatePerson = useCallback(
		(propertyName, value) => {
			updatePerson(getDatahubApi, person, propertyName, value)
				.then(({ person }) => setPerson(person))
				.catch((e) => setErrorMessage(`couldn't update person ${e.message}`));
		},
		[getDatahubApi, person, setPerson, setErrorMessage]
	);

	useEffect(() => {
		getPerson(getDatahubApi, person_id)
			.then((state) => {
				setPerson(state.person);
				onChange(state.metadata);
				setSectionFields(createPersonSectionFields(state.person.name));
			})
			.catch((e) => setErrorMessage(`couldn't find person ${e.message}`));
	}, [getDatahubApi, person_id, setPerson, onChange, setSectionFields]);

	const panelItems = useMemo(() => {
		const panelItems = [];

		if (!isNullOrUndefined(metadata)) {
			panelItems.push({ label: 'Items', content: <TablePanel items={metadata.sections} fields={sectionFields} /> });
		}
		panelItems.push({
			label: 'Social Handles',
			content: (
				<Grid>
					<SocialNetworkHandleEditor
						metadata={metadata}
						onChange={onChange}
						sourceReference={person?.extracted_entity_id}
						sourceReferenceType={sourceReferenceTypes.extractedEntities}
					/>
				</Grid>
			)
		});
		panelItems.push({ label: 'JSON', content: <DebugView object={person} /> });

		return panelItems;
	}, [metadata, person, sectionFields]);

	const renderSimpleMessage = (message) => {
		return (
			<Grid container>
				<Grid item>{message}</Grid>
			</Grid>
		);
	};

	if (!isNullOrUndefined(errorMessage)) {
		return renderSimpleMessage(errorMessage);
	}

	if (isNullOrUndefined(person)) {
		return (
			<div className="full-width">
				<SkeletonWrapper />
			</div>
		);
	}

	return <EntityAccordion entity={person} panelItems={panelItems} onUpdateEntity={handleUpdatePerson} />;
};

export default Person;
