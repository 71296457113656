export const errorCodeMapping = {
	400: 'Bad Request',
	401: 'Unauthorized',
	403: 'Forbidden',
	404: 'Resource not found',
	418: "I'm a teapot!",
	500: 'Server Error',
	501: 'Not Implemented',
	600: 'Failed to load application configuration'
};
