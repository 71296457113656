import React from 'react';
import Link from '@mui/material/Link';
import { handleError } from '../../../components/Api';

/**
 * Get a single Company object by entity ID
 * @param {*} param0
 * @param {*} entity_id
 */
export const getCompany = (getDatahubApi, entity_id) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/companies/v1/${entity_id}`)
			.then(({ data, ..._requestInfo }) => {
				let { company, metadata, ...other } = data;

				metadata.sections.unshift({
					label: 'All',
					count: metadata.item_count
				});

				metadata.sections.forEach((x) => {
					x.company_name = company.name;
				});

				return {
					company,
					metadata,
					...other
				};
			})
			.catch((e) => handleError(`Failed to load company ${entity_id}`, e, {}))
	);
};

export const updateCompany = (getDatahubApi, company, propertyName, value) => {
	return getDatahubApi().then((api) =>
		api
			.patch('/companies/v1/', {
				extracted_entity_id: company.extracted_entity_id,
				[propertyName]: value
			})
			.then(
				() => {
					return {
						company: {
							...company,
							[propertyName]: value
						}
					};
				},
				(fail) => {
					console.error('failed to save the company', fail);
					return { message: 'failed to save the company' };
				}
			)
	);
};

export const createCompanySectionFields = (companyName) => {
	return [
		{
			label: 'Section',
			property: 'label',
			render: (value, data, index) => (index === 0 ? <b>{value}</b> : value)
		},
		{
			label: 'Articles',
			property: 'count',
			render: (value, data, index) => {
				return (
					<Link
						className="clickable"
						href={
							index === 0
								? `/media/search?query=${encodeURI(`author:"${companyName}"`)}`
								: `/media/search?query=${encodeURI(`source_section_name:"${data.label}" AND author:"${companyName}"`)}`
						}
						target="_blank"
					>
						{value.toLocaleString()}
					</Link>
				);
			}
		}
	];
};
