import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, LinearProgress, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@truescope-web/react/lib/components/form/Button';
import Checkbox from '@truescope-web/react/lib/components/form/Checkbox';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../components/ConfigProvider';
import { addItemsToScope, getScope, getScopeItemCounts, getWorkspaces } from '../ScopeConstants';

const itemCountMin = 1;
const itemCountMax = 100000;

const AddItemsToScope = ({ addToWorkspaces, scope_id, onItemsAdded, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [itemCountLoading, setItemCountLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState(() => moment().local().format('YYYY-MM-DD'));
	const [today] = useState(() => moment().local());
	const [scope, setScope] = useState(null);
	const [itemCounts, setItemCounts] = useState(null);
	const { config } = useConfig();
	const unmatchedCountValid = useMemo(() => itemCounts?.unmatched >= itemCountMin && itemCounts?.unmatched <= itemCountMax, [itemCounts]);
	const [workspaces, setWorkspaces] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();

	useEffect(() => {
		if (addToWorkspaces) {
			setIsLoading(true);
			getWorkspaces(getDatahubApi, scope_id)
				.then(({ workspaces, message }) => {
					if (!stringIsNullOrEmpty(message)) {
						showSnackbar(message, snackbarVariants.error);
					} else {
						setWorkspaces((workspaces || []).map((workspace) => ({ ...workspace, checked: true })));
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setWorkspaces(null);
		}
	}, [addToWorkspaces]);

	useEffect(() => {
		setScope(null);
		setLoading(true);
		getScope(getDatahubApi, scope_id)
			.then(({ scope: newScope, message }) => {
				if (!stringIsNullOrEmpty(message)) {
					showSnackbar(message, snackbarVariants.error);
					setScope(null);
					setLoading(false);
					return;
				}
				setScope(newScope);
			})
			.finally(() => setLoading(false));
	}, [config.searchFieldVariants]);

	useEffect(() => {
		setItemCountLoading(true);
		getScopeItemCounts(getDatahubApi, scope_id, selectedDate, null)
			.then(({ matchedCount, unmatchedCount, message }) => {
				if (!stringIsNullOrEmpty(message)) {
					showSnackbar(message, snackbarVariants.error);
				}
				setItemCounts({ matched: matchedCount, unmatched: unmatchedCount });
			})
			.finally(() => setItemCountLoading(false));
	}, [selectedDate]);

	const handleCancel = () => {
		onClose();
	};

	const handleDateChanged = (e) => {
		const newSelectedDate = e.target.value;
		if (moment(newSelectedDate, 'YYYY-MM-DD') > today) {
			//cannot select future dates
			e.preventDefault();
			return;
		}
		setSelectedDate(newSelectedDate);
	};

	const handleAdd = () => {
		setLoading(true);

		addItemsToScope(
			getDatahubApi,
			scope.scope_id,
			selectedDate,
			addToWorkspaces ? workspaces.filter(({ checked }) => checked).map(({ workspace_id }) => workspace_id) : undefined
		)
			.then(({ message }) => {
				if (!stringIsNullOrEmpty(message)) {
					showSnackbar(message, snackbarVariants.error);
					return;
				}

				showSnackbar(
					`${itemCounts.unmatched} item${itemCounts.unmatched === 1 ? '' : 's'} added to ${scope.name}`,
					snackbarVariants.success
				);

				if (onItemsAdded) {
					onItemsAdded(itemCounts.unmatched);
				}

				handleCancel();
			})
			.finally(() => setLoading(false));
	};

	const handleWorkspaceChecked = (index, checked) => {
		setWorkspaces((previous) => {
			previous[index].checked = checked;
			return previous;
		});
	};

	const renderWorkspaces = () => {
		if (isLoading) {
			return (
				<div className="full-width-loader">
					<CircularProgress />
				</div>
			);
		}

		if (arrayIsNullOrEmpty(workspaces)) {
			return <em>This scope is not attached to any workspaces</em>;
		}

		return (
			<Table>
				<TableBody>
					{workspaces.map((workspace, index) => (
						<TableRow className="clickable" key={workspace.workspace_scope_id}>
							<TableCell padding="checkbox">
								<Checkbox
									checked={workspaces[index].checked}
									onChange={(_e, checked) => handleWorkspaceChecked(index, checked)}
								/>
							</TableCell>
							<TableCell>{workspace.name}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	};

	const renderAddToScopeTitle = () => {
		return (
			<Typography variant="body">
				Add historical items to feed that match the existing feed search conditions and that are not currently matched to this feed.
			</Typography>
		);
	};

	const renderAddToWorkspaceScopeTitle = () => {
		return (
			<Typography variant="body">
				Add historical items to this workspace that match the {scope.name} feed and that are not currently matched to this
				workspace.
			</Typography>
		);
	};

	return (
		<div className="sheet__content sheet__content--padded">
			<Grid container spacing={3}>
				{loading && (
					<Grid item>
						<LinearProgress />
					</Grid>
				)}
				{!loading && !isNullOrUndefined(scope) && (
					<>
						<Grid item>
							<Typography variant="h4">{scope.name}</Typography>
						</Grid>
						{addToWorkspaces ? (
							<>
								<Grid item>{renderAddToWorkspaceScopeTitle()}</Grid>
								<Grid item>{renderWorkspaces()}</Grid>
							</>
						) : (
							<Grid item>{renderAddToScopeTitle()}</Grid>
						)}
						<Grid item>
							<Inline>
								<TextField
									disabled={itemCountLoading}
									type="date"
									labelAbove
									label="Add items from"
									value={selectedDate}
									onChange={handleDateChanged}
									fullWidth={false}
									inputProps={{ max: today }}
								/>
							</Inline>
						</Grid>
						{itemCounts?.unmatched > itemCountMax && (
							<Grid item>
								<Alert severity="error">
									The number of matched items is over the bulk processing limit. Please discuss with the devteam on #data
									in Slack, provide Feed ID and details of what you are trying to achieve.
								</Alert>
								<Typography variant="subtitle"></Typography>
							</Grid>
						)}
						{!isNullOrUndefined(itemCounts?.matched) && (
							<Grid item>
								<Typography variant="body">
									There are {itemCounts.matched} currently matched items, and {itemCounts.unmatched} items that match the
									current search conditions but have not been added to the feed.
								</Typography>
							</Grid>
						)}
						<Grid item>
							<Inline>
								<Button
									onClick={handleAdd}
									variant="primary"
									disabled={loading || itemCountLoading || stringIsNullOrEmpty(selectedDate) || !unmatchedCountValid}
								>
									Add{' '}
									{!isNullOrUndefined(itemCounts?.unmatched) &&
										`${itemCounts?.unmatched} item${itemCounts?.unmatched === 1 ? `` : `s`}`}
								</Button>
								{itemCountLoading && <CircularProgress />}
							</Inline>
						</Grid>
					</>
				)}
			</Grid>
		</div>
	);
};

export default AddItemsToScope;
