import React from 'react';
import ClickToEditField from '@truescope-web/react/lib/components/form/ClickToEditField';
import Accordion from '@truescope-web/react/lib/components/layout/Accordion';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import LastUpdated from '../LastUpdated';

const EntityAccordion = ({ entity, panelItems, onUpdateEntity }) => (
	<Grid container>
		<Grid item>
			<Inline>
				<ClickToEditField
					value={entity?.name}
					typographyVariant="h4"
					fullWidth={false}
					loading={false}
					onAccept={(value) => onUpdateEntity('name', value)}
				/>
			</Inline>
		</Grid>
		<Grid item>
			<Accordion initialExpandedIndex={panelItems.length > 1 ? 0 : -1} items={panelItems} />
			<LastUpdated name={entity?.user_context?.name} date={entity?.date_modified_utc} />
		</Grid>
	</Grid>
);

export default EntityAccordion;
