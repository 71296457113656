import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LinearProgress, Link, Tooltip } from '@mui/material';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Button from '@truescope-web/react/lib/components/form/Button';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Paper from '@truescope-web/react/lib/components/layout/Paper';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../../components/Api';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { formatPagingInfoText, renderPlaintextCell } from '../../../components/Dx/DxGridConstants';
import Header from '../../../components/Header';
import { createFeedRule, feedNamesLookup, getFeedRule, getRulePreview, updateFeedRule } from './RuleConstants';

const PreviewDataGrid = ({ tweets }) => {
	const handleRenderUserCell = ({ value }) => {
		return <div className="wrap">@{value}</div>;
	};

	return (
		<DataGrid
			id="rule-preview-table"
			showRowLines
			dataSource={tweets}
			showColumnLines={false}
			showBorders
			onContentReady={formatPagingInfoText}
		>
			<FilterRow visible applyFilter="auto" />

			<HeaderFilter visible />

			<Column
				width={150}
				caption="User"
				dataField="username"
				cssClass="cell cell--pad"
				cellRender={handleRenderUserCell}
				allowSorting={false}
				allowSearch
			>
				<HeaderFilter visible={false} />
			</Column>

			<Column caption="Tweet" dataField="text" cssClass="cell cell--pad" allowSearch cellRender={renderPlaintextCell}>
				<HeaderFilter allowSearch />
			</Column>

			<Column
				caption="Date"
				width={200}
				dataType="datetime"
				format="HH:mm dd/MM/yy"
				dataField="created_at"
				alignment="right"
				cssClass="cell cell--pad"
				allowHeaderFiltering={false}
				allowFiltering={false}
				allowSorting
				allowSearch={false}
			>
				<HeaderFilter allowSearch />
			</Column>

			<Paging defaultPageSize={10} />

			<Pager showPageSizeSelector allowedPageSizes={[5, 10, 20]} showInfo />
		</DataGrid>
	);
};

const ToolbarButtons = ({ estimatedMonthlyTotal, setIsSaving, isSaving, feedRule, canSave, onPreview, canPreview }) => {
	const history = useHistory();
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();

	const handleSaveRule = async () => {
		const saveRuleFunc = isNullOrUndefined(feedRule?.feed_rule_id) ? createFeedRule : updateFeedRule;
		setIsSaving(true);
		try {
			const { message } = await saveRuleFunc(getDatahubApi, feedRule);
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(`Failed to create feed rule - ${message}`);
			}
			showSnackbar('Rule has been successfully saved', snackbarVariants.success);
			history.go(-1);
		} catch (e) {
			const msg = extractError(e);
			showSnackbar(
				msg.includes('feed_rules_feed_id_rule_key') ? 'This query conditions in this rule already exist in another rule' : msg,
				snackbarVariants.error
			);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
			<div>
				{!isNullOrUndefined(estimatedMonthlyTotal) && (
					<>
						<p>Forecasted items per month</p>
						<h3>{estimatedMonthlyTotal}</h3>
					</>
				)}
			</div>
			<Button
				variant="secondary"
				onClick={() => {
					history.go(-1);
				}}
			>
				Cancel
			</Button>
			<Button disabled={!canPreview} variant="secondary" onClick={onPreview}>
				Preview
			</Button>
			{canSave ? (
				<Button disabled={isSaving} onClick={handleSaveRule}>
					Save
				</Button>
			) : (
				<Tooltip title="You must preview the query before saving, to ensure it works correctly">
					<span>
						<Button disabled>Save</Button>
					</span>
				</Tooltip>
			)}
		</Inline>
	);
};

const Rule = ({ create, feedId }) => {
	const match = useRouteMatch();
	const { feed_rule_id: feedRuleId } = match.params;
	const { showSnackbar } = useSnackbar();
	const [getDatahubApi] = useApiLookup();
	const [canSave, setCanSave] = useState(false);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [feedRule, setFeedRule] = useState(null);
	const [results, setResults] = useState(null);

	const loadFeedRule = useCallback(async () => {
		setIsLoading(true);
		setErrorMessage(null);
		setFeedRule(null);
		try {
			if (create) {
				setFeedRule({
					feed_id: feedId,
					name: '',
					rule: '',
					preview_item_count: 20,
					is_enabled: true
				});
			} else {
				const { feed_rule: loadedFeedRule } = await getFeedRule(getDatahubApi, feedRuleId);
				setFeedRule(loadedFeedRule);
			}
		} catch (e) {
			const msg = `failed to load feed - ${e.message}`;
			console.error(msg, e);
			setErrorMessage(msg);
		} finally {
			setIsLoading(false);
		}
	}, [create, getDatahubApi, setFeedRule, setIsLoading, feedRuleId]);

	useEffect(() => {
		loadFeedRule();
	}, [loadFeedRule]);

	const loadPreviewData = useCallback(async () => {
		if (!(create || feedRule?.is_enabled)) {
			setCanSave(true);
			return;
		}

		setIsPartialLoading(true);
		try {
			if (stringIsNullOrEmpty(feedRule?.rule)) {
				throw new Error(`feed rule query is empty`);
			}
			const { tweets, estimatedMonthlyTotal, errorMessage } = await getRulePreview(getDatahubApi, feedRule.rule);
			if (!stringIsNullOrEmpty(errorMessage)) {
				throw new Error(errorMessage);
			}
			setCanSave(true);
			setResults({
				estimatedMonthlyTotal,
				tweets
			});
		} catch (e) {
			setResults({
				errorMessage: e.message
			});
			setCanSave(false);
		} finally {
			setIsPartialLoading(false);
		}
	}, [create, feedRule?.rule, feedRule?.is_enabled, showSnackbar, setIsPartialLoading, setCanSave, setResults]);

	const handleRuleChange = (e) => {
		setFeedRule((prev) => ({ ...prev, rule: e.target.value }));
		setCanSave(false);
	};

	const renderPreview = () => {
		if (isPartialLoading) {
			return (
				<Grid item>
					<LinearProgress />
				</Grid>
			);
		}

		if (isNullOrUndefined(results)) {
			return (
				<Grid item>
					Click <Link onClick={() => loadPreviewData()}>preview</Link> to view the rule results
				</Grid>
			);
		}

		if (!stringIsNullOrEmpty(results.errorMessage)) {
			return (
				<Grid item>
					<Alert severity="error">{results.errorMessage}</Alert>
				</Grid>
			);
		}

		if (arrayIsNullOrEmpty(results.tweets)) {
			return (
				<Grid item>
					<Alert severity="error">This query returned no items</Alert>
				</Grid>
			);
		}

		return (
			<Grid item>
				<PreviewDataGrid feedRule={feedRule} tweets={results.tweets} />
			</Grid>
		);
	};

	const renderContent = () => {
		if (!stringIsNullOrEmpty(errorMessage)) {
			return (
				<Grid item>
					<Alert severity="error">{errorMessage}</Alert>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12}>
					<Inline>
						<TextField
							labelAbove
							label="Rule Name"
							value={feedRule?.name}
							onChange={(e) => setFeedRule((prev) => ({ ...prev, name: e.target.value }))}
							disabled={isSaving || isPartialLoading}
						/>
					</Inline>
				</Grid>
				<Grid item xs={12}>
					<Inline>
						<TextField
							labelAbove
							label="Rule"
							value={feedRule?.rule}
							onChange={handleRuleChange}
							disabled={isSaving || isPartialLoading}
							minRows={4}
							maxRows={10}
							multiline
						/>
					</Inline>
				</Grid>
				<Grid item>
					<Divider />
				</Grid>
				<Grid item>
					<ToolbarButtons
						estimatedMonthlyTotal={results?.estimatedMonthlyTotal}
						setIsSaving={setIsSaving}
						isSaving={isSaving}
						feedRule={feedRule}
						canSave={!isSaving && !isPartialLoading && canSave}
						canPreview={!isSaving && !isPartialLoading && !stringIsNullOrEmpty(feedRule?.rule)}
						onPreview={loadPreviewData}
					/>
				</Grid>
				{renderPreview()}
				<Grid item>
					<Divider />
				</Grid>
				<Grid item>
					<Typography variant="subtitle" italic>
						last modified {stringIsNullOrEmpty(feedRule?.user_context?.name) ? '' : `by ${feedRule.user_context.name}`} at{' '}
						{moment.utc(feedRule.date_modified_utc).format('h:mma, DD/MM/YY')}
					</Typography>
				</Grid>
			</>
		);
	};

	return (
		<Content>
			<Header header={`${create ? 'Create' : 'Edit'} ${feedNamesLookup[feedId]} Rule`} />
			<Grid container>
				<Grid item>
					{isLoading ? (
						<LinearProgress />
					) : (
						<Paper>
							<Grid container>{renderContent()}</Grid>
						</Paper>
					)}
				</Grid>
			</Grid>
		</Content>
	);
};

export default Rule;
