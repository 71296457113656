import CustomStore from 'devextreme/data/custom_store';

export const createScopeWorkspacesTableDataSource = (workspaces) => {
	return {
		store: new CustomStore({
			load: () => {
				return Promise.resolve({
					data: workspaces
				});
			}
		})
	};
};
