import React, { memo, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTable } from 'react-table';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

export const WorkspaceScopesTable = memo(({ columns = [], data = [], onChange }) => {
	const [records, setRecords] = useState(data);

	// data may be empty when the table initialises
	useEffect(() => {
		setRecords(data);
	}, [data]);

	const getRowId = useCallback((row) => row.id, []);

	const { getTableProps, headerGroups, rows, prepareRow } = useTable({
		data: records,
		columns,
		getRowId
	});

	const handleDragEnd = (result) => {
		if (!result.destination || result.source.index === result.destination.index) {
			return;
		}

		onChange(records[result.source.index], result.source.index, result.destination.index);
	};

	return (
		<TableContainer className="reactTable" component={Paper}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="droppable">
					{(droppableProvided, _droppableSnapshot) => (
						<Table {...getTableProps()}>
							<TableHead>
								{headerGroups.map((headerGroup) => {
									const { key: rowHeaderKey, ...rowHeaderGroupProps } = headerGroup.getHeaderGroupProps();
									return (
										<TableRow key={rowHeaderKey} {...rowHeaderGroupProps}>
											<TableCell />
											{headerGroup.headers.map((column) => {
												const { key, ...columnHeaderProps } = column.getHeaderProps();
												return (
													<TableCell key={key} {...columnHeaderProps}>
														{column.render('Header')}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableHead>
							<TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
								{rows.map((row, index) => {
									const preparedRow = prepareRow(row);
									if (!isNullOrUndefined(preparedRow)) {
										return preparedRow;
									}

									const { key: rowKey, ...rowProps } = row.getRowProps();
									return <Row key={rowKey} index={index} row={row} {...rowProps} />;
								})}
								{droppableProvided.placeholder}
							</TableBody>
						</Table>
					)}
				</Droppable>
			</DragDropContext>
		</TableContainer>
	);
});

const Row = ({ row, index }) => (
	<Draggable draggableId={`${row.id}`} index={index}>
		{(draggableProvided, draggableSnapshot) => (
			<TableRow
				ref={draggableProvided.innerRef}
				{...draggableProvided.draggableProps}
				style={{
					width: '100%',
					...draggableProvided.draggableProps.style,
					background: draggableSnapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none'
				}}
				key={row.id}
			>
				<TableCell {...draggableProvided.dragHandleProps}>
					<DragIndicatorIcon />
				</TableCell>
				{row.cells.map((cell) => {
					const { key, ...cellProps } = cell.getCellProps();
					return (
						<TableCell key={key} {...cellProps} style={{ width: window.innerWidth }}>
							{cell.render('Cell')}
						</TableCell>
					);
				})}
			</TableRow>
		)}
	</Draggable>
);
