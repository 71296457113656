import React from 'react';
import BusinessOutlined from '@mui/icons-material/BusinessOutlined';
import CloudDownload from '@mui/icons-material/CloudDownloadOutlined';
import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import SupervisorAccountOutlined from '@mui/icons-material/SupervisorAccountOutlined';
import Avatar from '@truescope-web/react/lib/components/widgets/Avatar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

export const createDrawerItems = (_config) => {
	let drawerItems = [
		{
			icon: <LanguageOutlined />,
			label: 'Media',
			ariaLabel: 'media',
			items: [
				{
					label: 'Search',
					ariaLabel: 'media search',
					path: '/media/search'
				},
				{
					label: 'Feeds',
					ariaLabel: 'feeds',
					path: '/media/feeds'
				}
			]
		},
		{
			icon: <BusinessOutlined />,
			label: 'Clients',
			ariaLabel: 'clients',
			items: [
				{
					label: 'Workspaces',
					ariaLabel: 'workspaces',
					path: '/clients/workspaces'
				},
				{
					label: 'Users',
					ariaLabel: 'users',
					path: '/clients/users'
				}
			]
		},
		{
			icon: <CloudDownload />,
			label: 'Ingest',
			ariaLabel: '',
			items: createIngestSubMenuItems()
		},
		{
			icon: <SupervisorAccountOutlined />,
			label: 'Entities',
			ariaLabel: 'entities',
			items: createEntitiesSubMenuItems()
		}
	];

	return drawerItems;
};

const createEntitiesSubMenuItems = () => {
	return [
		{
			//icon: <ArrowForward/>,
			label: 'Channels',
			ariaLabel: 'entities channels',
			path: '/entities/channels'
		},
		{
			label: 'Authors',
			ariaLabel: 'entities authors',
			path: '/entities/authors'
		},
		{
			label: 'People',
			ariaLabel: 'entities people',
			path: '/entities/people'
		},
		{
			label: 'Companies',
			ariaLabel: 'entities companies',
			path: '/entities/companies'
		},
		{
			label: 'Entities',
			ariaLabel: 'Entities',
			path: '/entities'
		}
	];
};

const createIngestSubMenuItems = () => {
	let subMenuItems = [
		{
			label: 'Twitter',
			ariaLabel: 'ingest twitter',
			path: '/ingest/twitter/rules'
		}
	];

	return subMenuItems;
};

export const createDrawerFooterItems = (user, signOut) => {
	return [
		{
			icon: <Avatar tooltip={user.name} alt={user.name} imgSrc={user.picture} size={'sm'} />,
			label: user.name,
			ariaLabel: 'user name',
			items: [
				{
					icon: <PowerSettingsNew />,
					label: 'Sign out',
					ariaLabel: 'sign out',
					onClick: signOut
				}
			]
		}
	];
};

/**
 * gets the path from match and replaces ids with parameter names
 * @param {*} match
 */
export const getParameterPath = ({ path, params }) => {
	let newPath = path;
	const paramNames = Object.keys(params);
	if (!arrayIsNullOrEmpty(paramNames)) {
		const paramName = paramNames[0];
		const paramValue = params[paramName];
		newPath = newPath.replace(`/:${paramName}?`, '').replace(`/:${paramName}`, '');
		if (!stringIsNullOrEmpty(paramValue)) {
			newPath = `${newPath}/${paramValue}`;
		}
	}
	return newPath;
};

export const findSelectedAndExpandedDrawerItems = (drawerItems, match) => {
	const path = getParameterPath(match);

	let expanded = null,
		selected = null;

	for (let x = 0; x < drawerItems.length && expanded === null && selected === null; x++) {
		if (path === drawerItems[x].path) {
			expanded = selected = drawerItems[x];
		} else if (!arrayIsNullOrEmpty(drawerItems[x].items)) {
			for (let y = 0; y < drawerItems[x].items.length && selected === null; y++) {
				if (path.startsWith(drawerItems[x].items[y].path)) {
					selected = drawerItems[x].items[y];
					expanded = drawerItems[x];
				}
			}
		}
	}

	return {
		expanded,
		selected
	};
};
