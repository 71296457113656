import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useConfig } from '../../components/ConfigProvider';
import Header from '../../components/Header';

const Dashboard = ({ graph_id }) => {
	const [graph, setGraph] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const { config } = useConfig();
	const match = useRouteMatch();

	useEffect(() => {
		const graphId = parseInt(isNullOrUndefined(graph_id) ? match?.params?.graph_id : graph_id) || -1;
		const { graphs } = config;

		if (graphId < 0 || isNullOrUndefined(graphs)) {
			setErrorMessage('Invalid Graph.');
			return;
		}

		const newGraph = graphs.find((x) => {
			return x.graph_id === graphId;
		});

		if (isNullOrUndefined(newGraph)) {
			setErrorMessage('Graph not found.');
			return;
		}

		setGraph(newGraph);
		setErrorMessage(null);
	}, []);

	const render = () => {
		if (isNullOrUndefined(graph) && stringIsNullOrEmpty(errorMessage)) {
			return (
				<Content>
					<SkeletonWrapper />
				</Content>
			);
		} else if (!stringIsNullOrEmpty(errorMessage)) {
			return (
				<Content>
					<Header header="Error" breadcrumbs={false} />
					<Grid container>
						<Grid item>
							<div className="error-message">{errorMessage}</div>
						</Grid>
					</Grid>
				</Content>
			);
		}

		return (
			<div className="iframe-container">
				<iframe title={graph.label} className="external-graph" src={graph.value}></iframe>
			</div>
		);
	};

	return render();
};

export default Dashboard;
