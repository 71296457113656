import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncSelect from '@truescope-web/react/lib/components/form/AsyncSelect';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import useGeographiesApi from './useGeographiesApi';

const CitiesSelect = ({ regions, countries, cities, onChange, ...selectProps }) => {
	const geographiesApi = useGeographiesApi();
	const countryIds = useMemo(() => countries?.map((country) => country.value), [countries]);
	const regionIds = useMemo(() => regions?.map((region) => region.value), [regions]);
	const [defaultCities, setDefaultCities] = useState(undefined);

	useEffect(() => {
		if (arrayIsNullOrEmpty(regionIds) || arrayIsNullOrEmpty(countryIds)) {
			setDefaultCities(undefined);
			return;
		}
		geographiesApi?.getCities({ regionIds, countryIds }).then((newCities) => setDefaultCities(newCities));
	}, [setDefaultCities, geographiesApi?.getCities, regionIds, countryIds]);

	const handleLoadCities = useCallback(
		(input) =>
			geographiesApi.getCities({
				filterValue: input,
				countryIds,
				regionIds
			}),
		[geographiesApi?.getCities, countryIds, regionIds]
	);

	return (
		<AsyncSelect
			labelAbove
			isMulti
			label="Cities"
			onLoadOptions={handleLoadCities}
			defaultOptions={defaultCities}
			value={cities}
			placeholder="Cities"
			onChange={(e, value, rawValue) => onChange(e, value, rawValue, false)}
			menuPosition="fixed"
			{...selectProps}
		/>
	);
};

export default CitiesSelect;
