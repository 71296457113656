import { toDataURL } from '@truescope-web/utils/lib/api';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { handleError } from '../../../components/Api';

export const createUserData = async (getDatahubApi, userId, path) => {
	try {
		const api = await getDatahubApi();
		const { data } = await api.get(`/${path}/v1/${encodeURI(userId)}`);
		const { user } = data;

		if (!isNullOrUndefined(user) && !stringIsNullOrEmpty(user.picture)) {
			const { data: pictureData } = await toDataURL(user.picture);
			user.initialPictureData = pictureData;
		}
		return user;
	} catch (e) {
		return handleError(`Failed to load user ${userId}`, e, {});
	}
};

export const saveUser = async (getDatahubApi, { user_id, blocked, ...user }, profilePic, path) => {
	user.blocked = Boolean(user.blocked);

	const { initialPictureData, pictureData, picture } = profilePic;

	user.email = user.email.trim();
	user.name = user.name.trim();
	user.nickname = user.nickname.trim();

	if (initialPictureData !== pictureData && !stringIsNullOrEmpty(pictureData)) {
		//only upload if different
		user.pictureData = pictureData;
		delete user.picture;
	} else {
		user.picture = picture || '';
	}

	const api = await getDatahubApi();
	return await api.patch(path, user);
};

export const departmentOptions = [
	{
		label: 'PR and Communications',
		value: 'PR and Communications'
	},
	{
		label: 'Client Services/Account Management',
		value: 'Client Services/Account Management'
	},
	{
		label: 'Marketing',
		value: 'Marketing'
	},
	{
		label: 'Sales',
		value: 'Sales'
	},
	{
		label: 'Leadership',
		value: 'Leadership'
	},
	{
		label: 'HR',
		value: 'HR'
	},
	{
		label: 'Other',
		value: 'Other'
	}
];
