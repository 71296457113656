import DataGrid, { FilterRow, HeaderFilter, RemoteOperations, Scrolling } from 'devextreme-react/data-grid';
import { Column } from 'devextreme-react/tree-list';
import React, { useCallback, useState } from 'react';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { convertItems, createDataSource, renderPlaintextCell } from '../../../components/Dx/DxGridConstants';
import Header from '../../../components/Header';
import { useDataGridHeight } from '../../../hooks/useWindowHeight';
import Person from './Person';

const People = () => {
	const [getDatahubApi] = useApiLookup();
	const [showPersonSheet, setShowPersonSheet] = useState(false);
	const [selected, setSelected] = useState(null);
	const [dataSource, setDataSource] = useState(() =>
		createDataSource({
			getDatahubApi,
			requestPath: '/people/v1',
			convertItems,
			useWhereClauses: true
		})
	);

	const gridHeight = useDataGridHeight();

	const handleRowClick = useCallback(
		({ data }) => {
			if (isNullOrUndefined(data)) {
				return;
			}
			setSelected(data);
			setShowPersonSheet(true);
		},
		[setSelected, setShowPersonSheet]
	);

	const handleClosePersonSheet = useCallback(() => {
		setShowPersonSheet(false);
		setDataSource(
			createDataSource({
				getDatahubApi,
				requestPath: '/people/v1',
				convertItems,
				useWhereClauses: true
			})
		);
	}, [setShowPersonSheet, setDataSource]);

	const renderPersonSheet = () => {
		return (
			<Sheet isOpen={showPersonSheet} onClose={handleClosePersonSheet}>
				<div className="sheet-content">
					<Person person_id={selected?.extracted_entity_id} />
				</div>
			</Sheet>
		);
	};

	const renderGrid = () => {
		return (
			<DataGrid
				id="people-table"
				showRowLines
				dataSource={dataSource}
				hoverStateEnabled
				showColumnLines={false}
				onRowClick={handleRowClick}
				showBorders
				height={gridHeight}
				remoteOperations
				wordWrapEnabled
			>
				<Scrolling mode="infinite" />

				<FilterRow visible applyFilter="auto" />

				<HeaderFilter visible allowSearch />

				<Column
					caption="Person"
					dataField="name"
					cssClass="cell cell--pad clickable"
					cellRender={renderPlaintextCell}
					allowHeaderFiltering={false}
				>
					<HeaderFilter allowSearch />
				</Column>

				<RemoteOperations sorting filtering />
			</DataGrid>
		);
	};

	return (
		<>
			<Content>
				<Header header="People" />
				<Grid container>
					<Grid item>{renderGrid()}</Grid>
				</Grid>
			</Content>
			{renderPersonSheet()}
		</>
	);
};

export default People;
