import React from 'react';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import TextFieldWithDebounce from '@truescope-web/react/lib/components/form/TextFieldWithDebounce';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { formatJsonDateString } from '@truescope-web/utils/lib/dates';
import { lockAdornment } from '../constants';

const Plan = ({ plan, workspace, onPlanEndDateChange, validation, dateTo }) => {
	return (
		<Grid container>
			<Grid item>
				<Typography variant="h4">Plan</Typography>
			</Grid>
			<Grid item sm={6}>
				<TextField labelAbove label="Service Plan" value={plan.name} disabled adornment={lockAdornment} />
			</Grid>
			<Grid item sm={6}>
				<TextField labelAbove label="Data Pack" value="N/A" disabled adornment={lockAdornment} />
			</Grid>
			<Grid item sm={6}>
				<TextField
					labelAbove
					label="Plan Start Date"
					value={formatJsonDateString(workspace.plan_start_date)}
					disabled
					adornment={lockAdornment}
				/>
			</Grid>
			<Grid item sm={6}>
				<TextFieldWithDebounce
					delay={1500}
					labelAbove
					label="Plan End Date"
					type="date"
					onChange={onPlanEndDateChange}
					value={dateTo}
				/>
				<ValidationPlaceholder validationResult={validation?.results.plan_end_date} />
			</Grid>
		</Grid>
	);
};

export default Plan;
