import React from 'react';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';

const UserInfo = ({ setUser, showId, user, validationState }) => {
	const handleChange = (event) => {
		setUser((prev) => ({ ...prev, [event.target.id]: event.target.value }));
	};

	return (
		<>
			{showId && (
				<Grid item>
					<Inline>
						<TextField disabled label="Auth Id" value={user?.auth_id || ''} />
						<TextField disabled label="User Id" value={user?.user_id || ''} />
					</Inline>
				</Grid>
			)}
			<Grid item xs={6}>
				<TextField id="name" label="Name" value={user?.name || ''} onChange={handleChange} />
				<ValidationPlaceholder validationResult={validationState?.results?.name} />
			</Grid>
			<Grid item xs={6}>
				<TextField id="nickname" label="Nickname" value={user?.nickname || ''} onChange={handleChange} />
				<ValidationPlaceholder validationResult={validationState?.results?.nickname} />
			</Grid>
		</>
	);
};

export default UserInfo;
