import React, { useCallback } from 'react';
import AsyncSelect from '@truescope-web/react/lib/components/form/AsyncSelect';
import { ButtonGroup } from '@truescope-web/react/lib/components/form/Button';
import Button from '@truescope-web/react/lib/components/form/Button';
import ClickToEditField from '@truescope-web/react/lib/components/form/ClickToEditField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { searchOwners } from './SourceConstants';

const About = ({ source, setSource, setErrorMessage, mediaTypes, handleUpdateSource, isCreateMode }) => {
	const [getDatahubApi] = useApiLookup();
	const handleLoadOwners = useCallback(
		(input) =>
			searchOwners(getDatahubApi, input).then(({ sourceOwners, message }) => {
				if (!stringIsNullOrEmpty(message)) {
					setErrorMessage(message);
					console.error('an error occurred getting the owners:', message);
					return [];
				}

				return sourceOwners.map((sourceOwner) => ({
					label: sourceOwner.name,
					value: sourceOwner.source_owner_id,
					metadata: sourceOwner
				}));
			}),
		[getDatahubApi]
	);

	const handleAsyncSelectChange = (_event, _value, rawValue) => {
		//The field has been cleared
		if (isNullOrUndefined(rawValue) || isNullOrUndefined(rawValue.value)) {
			handleUpdateSource('source_owner_id', null);
			setSource((prev) => ({ ...prev, owner: null }));
			return;
		}

		let newSource = { ...source, owner: rawValue };

		const { label } = rawValue;

		if (rawValue.__isNew__) {
			handleUpdateSource('new_owner', label, newSource);
		} else {
			handleUpdateSource('source_owner_id', rawValue.value, newSource);
		}
	};

	const renderFormLabel = (label) => {
		return (
			<Grid item>
				<Inline>
					<Typography variant="body">{label}</Typography>
				</Inline>
			</Grid>
		);
	};

	return (
		<Grid container>
			{renderFormLabel('Media Owner')}
			<Grid item>
				<AsyncSelect
					placeholder="Add a Media Owner"
					creatable
					isMulti={false}
					noOptionsMessage={() => 'Begin typing to search for Media Owners'}
					value={source?.owner || null}
					onLoadOptions={handleLoadOwners}
					onChange={handleAsyncSelectChange}
					optionToValuePath={source?.owner}
					isClearable
				/>
			</Grid>
			{isCreateMode ? (
				<>
					{renderFormLabel('Media Type')}
					<Grid item>
						{!arrayIsNullOrEmpty(mediaTypes) && (
							<ButtonGroup value={source?.mediaType} variant="contained" fullWidth orientation="vertical">
								{mediaTypes.map((type) => (
									<Button
										key={type.media_type_id}
										variant={source?.media_type_id === type.media_type_id ? 'primary' : 'default'}
										onClick={() => handleUpdateSource('media_type_id', type.media_type_id)}
									>
										{type.name}
									</Button>
								))}
							</ButtonGroup>
						)}
					</Grid>
				</>
			) : (
				<>
					<Grid item>
						<Typography>Media Type</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle">
							{mediaTypes?.find(({ media_type_id }) => media_type_id === source?.media_type_id)?.name}
						</Typography>
					</Grid>
				</>
			)}
			{renderFormLabel('Source URL')}
			<Grid item>
				<ClickToEditField
					placeholder="Click to add the Source URL"
					value={source?.url}
					typographyItalic={false}
					typographyVariant="subtitle"
					fullWidth
					loading={false}
					onAccept={(value) => handleUpdateSource('url', value)}
				/>
			</Grid>
		</Grid>
	);
};

export default About;
