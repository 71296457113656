import React from 'react';
import { Route } from 'react-router-dom';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import AppLoader from '@truescope-web/react/lib/components/loading/AppLoader';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import IntervalView from './IntervalView';

const InternalRoute = ({ component: Component, render, componentProps = {}, ...other }) => {
	const userStorgageContext = useUserStorageContext();

	//have to separate this loading out to different things
	//because we need loginWithRedirect to occur
	if (!userStorgageContext.isInitialized) {
		return <AppLoader />;
	}

	//this may be a redirect, so let the route take care of the rendering
	if (isNullOrUndefined(Component)) {
		return <Route {...other} />;
	}

	let renderView;
	if (isNullOrUndefined(render)) {
		renderView = (props) => (
			<IntervalView {...props}>
				<Component {...props} {...componentProps} />
			</IntervalView>
		);
	} else {
		renderView = (props) => <IntervalView {...props}>{render(props)}</IntervalView>;
	}

	return <Route render={renderView} {...other} />;
};

export default InternalRoute;
