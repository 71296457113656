import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

export const handleError = (prefix, { response, ...other }, merge = {}) => {
	if (isNullOrUndefined(response)) {
		console.error('serious error occurred', other);
		return {
			message: (
				<div>
					<h3>{prefix}</h3>
				</div>
			)
		};
	}

	const { data } = response;
	console.error(prefix, JSON.stringify(data));
	return {
		message: (
			<div>
				<h3>{prefix}</h3>
				<b>{data.errorCode}</b>
				<p>{data.message}</p>
			</div>
		),
		...merge
	};
};

export const handleInlineError = (prefix, { response, ...other }, merge = {}) => {
	if (isNullOrUndefined(response)) {
		console.error('serious error occurred', other);
		return {
			message: (
				<Typography variant="h4" bold>
					{prefix}
				</Typography>
			)
		};
	}

	const { data } = response;
	console.error(prefix, JSON.stringify(data));
	return {
		message: (
			<MuiAlert severity="error">
				{prefix} - {data.message}
			</MuiAlert>
		),
		...merge
	};
};

/**
 * extracts an error from an api exception
 * @param {*} param0
 */
export const extractError = ({ response, ...other }) => {
	if (isNullOrUndefined(response)) {
		console.error('An unknown error occurred. Response was null', other);
		return 'serious error occurred';
	}

	const { data } = response;

	if (isNullOrUndefined(data)) {
		console.error('An unknown error occurred. Data was null', other);
		return 'serious error occurred';
	}

	console.error('An error occurred: ', data.message);
	return data.message;
};
