import React from 'react';
import Grid from '@truescope-web/react/lib/components/layout/Grid';

const LayoutSeparator = () => {
	return (
		<Grid container>
			<Grid item>
				<hr />
			</Grid>
		</Grid>
	);
};

export default LayoutSeparator;
