import React, { memo } from 'react';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import List from './List';

const ContentRights = memo(({ workspaceId, workspaceContentRights = [] }) => {
	return (
		<div className="workspace-content-rights">
			<Grid item className="workspace-content-rights__title">
				<Typography variant="subtitle">Choose what rights managed content should be available on this Workspace</Typography>
			</Grid>
			<Grid item>
				<List workspaceId={workspaceId} workspaceContentRights={workspaceContentRights} />
			</Grid>
		</div>
	);
});

export default ContentRights;
