import { useMemo } from 'react';
import ConditionGroup from './ConditionGroup';
import { createFieldOptions } from './QueryConditionGroupsBuilderConstants';

const QueryConditionGroupsBuilder = ({ queryConditionGroups, onChange }) => {
	const fieldOptions = useMemo(() => createFieldOptions(), []);

	const handleConditionGroupChange = (updatedQueryConditionGroup, index) => {
		const updatedQueryConditionGroups = [...queryConditionGroups];
		updatedQueryConditionGroups[index] = updatedQueryConditionGroup;
		onChange(updatedQueryConditionGroups);
	};

	const handleDeleteConditionGroup = (index) => {
		const updatedQueryConditionGroups = [...queryConditionGroups];
		updatedQueryConditionGroups.splice(index, 1);
		onChange(updatedQueryConditionGroups);
	};

	const handleChangeGroupOperator = (updatedGroupOperator, index) => {
		const updatedQueryConditionGroups = [...queryConditionGroups];
		updatedQueryConditionGroups[index] = {
			...updatedQueryConditionGroups[index],
			groupOperator: updatedGroupOperator
		};

		onChange(updatedQueryConditionGroups);
	};

	return (
		<div className="query-condition-groups-builder">
			{(queryConditionGroups || []).map((conditionGroup, index) => (
				<ConditionGroup
					key={conditionGroup.id}
					conditionGroup={conditionGroup}
					onChange={(updatedConditionGroup) => handleConditionGroupChange(updatedConditionGroup, index)}
					onChangeGroupOperator={(updatedGroupOperator) => handleChangeGroupOperator(updatedGroupOperator, index)}
					onDelete={() => handleDeleteConditionGroup(index)}
					fieldOptions={fieldOptions}
					showGroupOperator={index > 0}
				/>
			))}
		</div>
	);
};

export default QueryConditionGroupsBuilder;
