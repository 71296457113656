import { extractError } from '../../../components/Api';

export const searchEntities = (
	getDatahubApi,
	query,
	filterType,
	entity_types,
	offset = 0,
	limit = 10,
	orderBy = 'name',
	order = 'asc',
	cancelTokenReset
) => {
	return getDatahubApi().then((api) =>
		api
			.post(
				`/entities/v1/`,
				{
					query,
					filterType,
					entity_types,
					offset,
					limit,
					orderBy,
					order
				},
				{
					cancelToken: cancelTokenReset()
				}
			)
			.then(({ data }) => data)
			.catch((e) => {
				console.error(e);
				throw extractError(e);
			})
	);
};

export const hideOrShowEntities = (getDatahubApi, ids = [], hidden) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`/entities/v1/`, { ids, hidden })
			.then(({ data }) => ({
				success: data?.success
			}))
			.catch((e) => {
				throw extractError(e);
			})
	);
};

export const addParent = (getDatahubApi, parentId, ids = []) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`/entities/v1/parent/${parentId}`, { ids })
			.then(({ data }) => ({
				success: data?.success
			}))
			.catch((e) => {
				throw extractError(e);
			})
	);
};

export const removeParent = (getDatahubApi, parentId, id) => {
	return getDatahubApi().then((api) =>
		api
			.delete(`/entities/v1/remove/${parentId}/${id}`)
			.then(({ data }) => ({
				success: data?.success
			}))
			.catch((e) => {
				throw extractError(e);
			})
	);
};

export const getEntity = (getDatahubApi, id) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/entities/v1/${id}`)
			.then(({ data }) => data)
			.catch((e) => {
				throw extractError(e);
			})
	);
};

export const updateEntity = (getDatahubApi, id, fields = {}) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`/entities/v1/${id}`, fields)
			.then(({ data }) => ({
				success: data?.success
			}))
			.catch((e) => {
				throw extractError(e);
			})
	);
};

export const getSocialNetworks = (getDatahubApi) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/social-network/v1`)
			.then(({ data }) => data)
			.catch((e) => {
				throw extractError(e);
			})
	);
};
