import moment from 'moment';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

const cleanBool = (bool) => {
	Object.keys(bool || {}).forEach((boolProperty) => {
		if (arrayIsNullOrEmpty(bool[boolProperty])) {
			delete bool[boolProperty];
		}
	});
	return boolHasValue(bool) ? bool : undefined;
};

const boolHasValue = (bool) => Object.values(bool || {}).some((array) => !arrayIsNullOrEmpty(array));

const convertConditionToElasticQueryFilterFunction = (condition, searchFieldVariants) => {
	switch (condition.filterFunction) {
		case 'terms_set':
		case 'terms':
			return {
				[condition.filterFunction]: {
					[condition.field]: condition.value?.map((option) => option.value || option)
				}
			};
		case 'query_string':
		case 'query_string_fields':
			let fields;
			if (condition.field === 'all_fields') {
				fields = undefined;
			} else if (condition.field === 'all_fields_meta' || isNullOrUndefined(searchFieldVariants[condition.field])) {
				fields = Object.values(searchFieldVariants).reduce((fields, searchFields) => fields.concat(...searchFields), []);
			} else {
				fields = searchFieldVariants[condition.field];
			}
			return {
				query_string: {
					query: condition.value,
					fields
				}
			};
		case 'fuzzy':
		case 'exists':
		case 'nested':
		case 'not_exists':
		case 'prefix':
		case 'wildcard':
		case 'term':
			return {
				[condition.filterFunction]: {
					[condition.field]: {
						value: condition.value
					}
				}
			};
		case 'range':
		case 'range_gte_lte':
			return {
				range: {
					[condition.field]: {
						gte: condition.value.from,
						lte: condition.value.to
					}
				}
			};
		case 'range_gt':
			return {
				range: {
					[condition.field]: {
						gt: condition.value.from
					}
				}
			};
		case 'range_gte':
			return {
				range: {
					[condition.field]: {
						gte: condition.value.from
					}
				}
			};
		case 'range_lt':
			return {
				range: {
					[condition.field]: {
						lt: condition.value.to
					}
				}
			};
		case 'range_lte':
			return {
				range: {
					[condition.field]: {
						lte: condition.value.to
					}
				}
			};
		default: {
			const message = `unsupported condition.filterFunction '${condition.filterFunction}'`;
			console.error(message, condition);
			throw new Error(message);
		}
	}
};

const conditionHasValue = (condition) => {
	switch (condition.filterFunction) {
		case null:
		case undefined:
			return false;
		case 'terms_set':
		case 'terms':
			return !arrayIsNullOrEmpty(condition.value);
		case 'query_string':
		case 'query_string_fields':
		case 'fuzzy':
		case 'exists':
		case 'nested':
		case 'not_exists':
		case 'prefix':
		case 'wildcard':
		case 'term':
			return !stringIsNullOrEmpty(condition.value);
		case 'range':
		case 'range_gte_lte':
		case 'range_gt':
		case 'range_gte':
		case 'range_lt':
		case 'range_lte':
			return !isNullOrUndefined(condition.value?.from) || !isNullOrUndefined(condition.value?.to);
		default: {
			const message = `unsupported condition.filterFunction '${condition.filterFunction}'`;
			console.error(message, condition);
			throw new Error(message);
		}
	}
};

const convertQueryConditionGroupToBool = (queryConditionGroup, searchFieldVariants) =>
	cleanBool(
		queryConditionGroup.conditions.reduce(
			(bool, condition) => {
				if (conditionHasValue(condition)) {
					bool[queryConditionGroup.conditionsOperator].push(
						convertConditionToElasticQueryFilterFunction(condition, searchFieldVariants)
					);
				}
				return bool;
			},
			{
				must: [],
				should: [],
				must_not: []
			}
		)
	);

/**
 * validates query condition groups to ensure they can be converted to an elastic query
 * @param {*} queryConditionGroups
 */
const validateQueryConditionGroups = (queryConditionGroups) => {
	queryConditionGroups.forEach((conditionGroup) => {
		if (arrayIsNullOrEmpty(conditionGroup.conditions)) {
			throw new Error(
				`condition group ${conditionGroup.id} is empty. Condition groups must contain at least one condition or be removed`
			);
		}
	});
};

const createPartitionsByShould = (queryConditionGroups) => {
	const partitions = [];
	queryConditionGroups.forEach((conditionGroup, index) => {
		if (index === 0 || conditionGroup.groupOperator === 'should') {
			//if its the first group OR a 'should', start a new group
			partitions.push([]);
		}
		const lastGroup = partitions[partitions.length - 1];
		lastGroup.push(conditionGroup);
	});
	return partitions;
};

const convertPartitionsToBool = (partitions, searchFieldVariants) => {
	let bools = [];

	partitions.forEach((partition) => {
		let bool = {};
		partition.forEach(({ conditions, conditionsOperator, groupOperator }) => {
			const groupBool =
				conditions.length === 1
					? convertConditionToElasticQueryFilterFunction(conditions[0], searchFieldVariants)
					: {
							bool: convertQueryConditionGroupToBool({ conditions, conditionsOperator }, searchFieldVariants)
					  };

			if (groupOperator === 'must_not') {
				//if we encounter a must_not, we need to push the entire collection of bools down into itself, with the must_not adjacent
				bools = [
					{
						bool: arrayIsNullOrEmpty(bools)
							? {
									must: bool.must,
									must_not: [groupBool]
							  }
							: {
									should: bools.concat([{ bool }]),
									must_not: [groupBool]
							  }
					}
				];
				bool = {};
			} else {
				if (isNullOrUndefined(bool.must)) {
					bool.must = [];
				}
				bool.must.push(groupBool);
			}
		});

		if (boolHasValue(bool)) {
			bools.push({ bool });
		}
	});

	return bools;
};

const convertToLogicalBool = (queryConditionGroups, searchFieldVariants) => {
	validateQueryConditionGroups(queryConditionGroups);
	const partitions = createPartitionsByShould(queryConditionGroups);
	const shouldGroupBools = convertPartitionsToBool(partitions, searchFieldVariants);
	return shouldGroupBools.length === 1 ? shouldGroupBools[0].bool : { should: shouldGroupBools };
};

export const convertQueryConditionGroupsToElasticSearchQuery = (queryConditionGroups, searchFieldVariants) => {
	try {
		const queryGroupBool = convertToLogicalBool(queryConditionGroups, searchFieldVariants);

		if (!boolHasValue(queryGroupBool)) {
			return null;
		}

		return {
			bool: {
				must: [
					{
						bool: queryGroupBool
					},
					{
						bool: {
							should: [
								{
									bool: {
										must_not: [
											{
												exists: {
													field: 'app_expire_time'
												}
											}
										]
									}
								},
								{
									range: {
										app_expire_time: {
											gte: 'now'
										}
									}
								}
							]
						}
					}
				]
			}
		};
	} catch (e) {
		const message = `failed to convert conditions to elastic query - ${e.message}`;
		console.error(message, e, queryConditionGroups);
		throw new Error(message);
	}
};

export const createElasticSearchPreview = (queryConditionGroups, searchFieldVariants, timeRangeDays = 30) => {
	const elasticQueryBool = convertQueryConditionGroupsToElasticSearchQuery(queryConditionGroups, searchFieldVariants);
	return {
		elasticQueryBool: elasticQueryBool,
		elasticSearchPreview: {
			bool: {
				must: [
					elasticQueryBool,
					{
						bool: {
							must: {
								range: {
									publication_date: {
										gte: moment().utc().subtract(timeRangeDays, 'days').toJSON()
									}
								}
							}
						}
					}
				]
			}
		}
	};
};
