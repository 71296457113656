import React from 'react';
import Drawer from './Drawer/Drawer';

const IntervalView = ({ children, _header, _breadcrumbs, ...props }) => {
	return (
		<>
			<Drawer {...props} />
			<div className="view">{children}</div>
		</>
	);
};

export default IntervalView;
