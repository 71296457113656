import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

/**
 * gets currencies from the api
 * @param {object} getDatahubApi
 * @param {string} currencyName currencies that start with a given name
 * @returns array of currency option objects
 */
export const getCurrencies = async (getDatahubApi) => {
	try {
		const api = await getDatahubApi();
		const { data } = await api.post('/workspaces/v1/currencies', { limit: 1000 });

		if (!stringIsNullOrEmpty(data.message)) {
			throw new Error(data.message);
		}

		if (arrayIsNullOrEmpty(data)) {
			return [];
		}

		return data.map((item) => ({ label: `${item.currency_name} (${item.currency_code})`, value: item.currency_code }));
	} catch (e) {
		return undefined;
	}
};
