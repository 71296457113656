import { useEffect, useMemo, useState } from 'react';

/**
 * gets the height of the window
 */
const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

/**
 * throttle of the timeout
 */ //
const throttleMs = 150;

/**
 * throttles getting the height of the window
 */
const useWindowHeight = () => {
	const [height, setHeight] = useState(getHeight());

	useEffect(() => {
		let timeoutId = null;

		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setHeight(getHeight()), throttleMs);
		};

		window.addEventListener('resize', resizeListener);

		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return height;
};

export const useDataGridHeight = (small = false) => {
	const windowHeight = useWindowHeight();
	const standardGridHeight = small ? 440 : 480;
	const standardWindowHeight = 657;
	return useMemo(
		() => windowHeight * (standardGridHeight / standardWindowHeight),
		[windowHeight, standardGridHeight, standardWindowHeight]
	);
};

export default useWindowHeight;
