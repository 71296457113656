import { googleLogout } from '@react-oauth/google';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import View from '@truescope-web/react/lib/components/layout/View';
import { useGoogleAuthContext } from '../../components/GoogleAuth/GoogleAuthProvider';
import { clearAuthenticationState } from '../../components/GoogleAuth/reducer';
import Header from '../../components/Header';

const SignOut = ({ automatic }) => {
	const [{ isAuthenticated }, dispatchGoogleAuth] = useGoogleAuthContext();
	const history = useHistory();

	const logout = async () => {
		try {
			await googleLogout();
		} catch (e) {
			console.error(`failed to log out of google - ${e.message}`, e);
		}
		dispatchGoogleAuth(clearAuthenticationState());
	};

	return (
		<View>
			<Header header="Sign out" breadcrumbs={false} />
			<Grid container>
				<Grid item>
					{isAuthenticated ? (
						automatic ? (
							googleLogout()
						) : (
							<>
								<Link onClick={logout}>Click Here</Link> to sign out
							</>
						)
					) : (
						<>
							you are signed out. <Link onClick={() => history.push('/')}>Click Here</Link> to sign in again
						</>
					)}
				</Grid>
			</Grid>
		</View>
	);
};

export default SignOut;
