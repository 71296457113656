import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import TablePanel from '@truescope-web/react/lib/components/layout/TablePanel';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import DebugView from '@truescope-web/react/lib/components/widgets/DebugView';
import { sourceReferenceTypes } from '@truescope-web/utils/lib/entityHelpers';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import EntityAccordion from '../../../components/Widgets/EntityAccordion';
import SocialNetworkHandleEditor from '../../../components/Widgets/SocialNetworkHandleEditor';
import { createAuthorSectionFields, getAuthor, updateAuthor } from './AuthorConstants';

const Author = ({ match, author_id: aid }) => {
	const author_id = !stringIsNullOrEmpty(aid) ? aid : match.params.author_id;
	const [getDatahubApi] = useApiLookup();

	const [sectionFields, setSectionFields] = useState(null);
	const [author, setAuthor] = useState(null);
	const [metadata, onChange] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleUpdateAuthor = useCallback(
		(propertyName, value) => {
			updateAuthor(getDatahubApi, author, propertyName, value)
				.then(({ author }) => setAuthor(author))
				.catch((e) => setErrorMessage(`couldn't update author ${e.message}`));
		},
		[getDatahubApi, author, setAuthor, setErrorMessage]
	);

	useEffect(() => {
		getAuthor(getDatahubApi, author_id)
			.then((state) => {
				setAuthor(state.author);
				onChange(state.metadata);
				setSectionFields(createAuthorSectionFields(state.author.name));
			})
			.catch((e) => setErrorMessage(`couldn't find author ${e.message}`));
	}, [getDatahubApi, author_id, setAuthor, onChange, setSectionFields, setErrorMessage]);

	const panelItems = useMemo(() => {
		const panelItems = [];

		if (!isNullOrUndefined(metadata)) {
			panelItems.push({ label: 'Items', content: <TablePanel items={metadata?.sections} fields={sectionFields} /> });
		}
		panelItems.push({
			label: 'Social Handles',
			content: (
				<Grid>
					<SocialNetworkHandleEditor
						metadata={metadata}
						onChange={onChange}
						sourceReference={author?.author_id}
						sourceReferenceType={sourceReferenceTypes.authors}
					/>
				</Grid>
			)
		});
		if (!isNullOrUndefined(metadata)) {
			panelItems.push({ label: 'JSON', content: <DebugView object={{ author: author, metadata: metadata }} /> });
		}

		return panelItems;
	}, [metadata, author, sectionFields]);

	if (!isNullOrUndefined(errorMessage)) {
		return (
			<Grid container>
				<Grid item>{errorMessage}</Grid>
			</Grid>
		);
	}

	if (isNullOrUndefined(author)) {
		return (
			<div className="full-width">
				<SkeletonWrapper />
			</div>
		);
	}

	return <EntityAccordion entity={author} panelItems={panelItems} onUpdateEntity={handleUpdateAuthor} />;
};

export default Author;
