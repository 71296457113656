import axios from 'axios';
import { getUser } from '@truescope-web/utils/lib/google';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import config from '../../config.json';

/**
 * local storage key for auth storage
 */
export const authStorageKey = 'auth';

/**
 * gets the persisted authentication state from local storage
 * @returns
 */
export const getAuthState = () => {
	try {
		const authenticationStateString = localStorage.getItem(authStorageKey);
		if (!isNullOrUndefined(authenticationStateString)) {
			const { refreshToken, accessToken, expiry, user } = JSON.parse(authenticationStateString);
			return {
				refreshToken,
				accessToken,
				expiry,
				user,
				isAuthenticated: true
			};
		}
	} catch (e) {
		console.error(`failed to load initial state - ${e.message}`, e);
		localStorage.removeItem(authStorageKey);
	}

	return { isAuthenticated: false };
};

/**
 * given a refresh token, provides new access/refresh tokens
 * @param {{ code: string, refreshToken: string }} body either a refresh token or code
 */
export const getRefreshToken = async (body) => {
	const {
		data: { message, refreshToken, accessToken, expiry },
		status
	} = await axios.post(`${config.api.datahub.baseUrl}authorizers/v1/token`, body, {
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
	});

	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}

	if (status !== 200) {
		throw new Error(`http ${status} error`);
	}

	const user = await getUser(accessToken);

	return {
		refreshToken,
		accessToken,
		expiry,
		user
	};
};

/**
 * sleeps the current function
 */
export const sleep = async (durationMs) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, durationMs);
	});
};
