import { formatDate } from '@truescope-web/utils/lib/dates';
import { workspaceStatesLookup } from '@truescope-web/utils/lib/workspaces';

export const convertItems = (items, config) => {
	return items.map((item) => {
		return {
			...item,
			market: config?.marketsLookup?.[item.market_id]?.name,
			...formatDate('plan_end_date', item, 'DD/MM/YYYY')
		};
	});
};

export const statusHeaderFilter = [
	{
		text: 'Inactive',
		value: workspaceStatesLookup.inactive
	},
	{
		text: 'Active',
		value: workspaceStatesLookup.active
	}
];
