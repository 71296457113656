import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import ImageUploadCropper, { cropperStylesLookup } from '@truescope-web/react/lib/components/form/ImageUploadCropper';
import SourceLogo from '@truescope-web/react/lib/components/widgets/SourceLogo';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { uploadTypes } from '@truescope-web/utils/lib/upload';
import { uploadFile } from '../../../api/upload';
import { useApiLookup } from '../../../components/ApiLookupProvider';

const getFileName = ({ override_profile_image_url, source_id }) => {
	if (stringIsNullOrEmpty(override_profile_image_url)) {
		return stringIsNullOrEmpty(source_id) ? `source-${uuidv4()}` : `source-${source_id}`;
	}
	return override_profile_image_url.split('/').pop();
};

const SourceLogoUploader = ({ source, onChange }) => {
	const [getDatahubApi] = useApiLookup();

	const handleUploadFile = (payload) => {
		const fileName = getFileName(source);
		return uploadFile(getDatahubApi, fileName, uploadTypes.entityPicture, payload.data);
	};

	return (
		<div
			className={`source-logo-uploader ${
				stringIsNullOrEmpty(source?.source_profile_image_url) ? 'source-logo-uploader--fallback' : ''
			}`}
		>
			<ImageUploadCropper
				url={source?.override_profile_image_url}
				alt="Source logo"
				defaultIcon={<SourceLogo sourceUrl={source?.url} sourceName={source?.name} avatarOnly />}
				onUpload={(payload) => handleUploadFile(payload)}
				onChange={(fileUrl) => onChange(fileUrl)}
				maxCropWidth={100}
				maxCropHeight={100}
				viewMode={0}
				cropperStyle={cropperStylesLookup.avatar}
			/>
		</div>
	);
};

export default SourceLogoUploader;
