import React from 'react';
import Link from '@mui/material/Link';
import { handleError } from '../../../components/Api';

export const getAuthor = (getDatahubApi, author_id) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/authors/v1/${author_id}`)
			.then(({ data }) => {
				let { author, metadata, ...other } = data;

				metadata.sections.unshift({
					label: 'All',
					count: metadata.item_count
				});

				metadata.sections.forEach((x) => {
					x.author_name = author.name;
				});

				return {
					author,
					metadata,
					...other
				};
			})
			.catch((e) => handleError(`Failed to load author ${author_id}`, e, {}))
	);
};

export const updateAuthor = (getDatahubApi, author, propertyName, value) => {
	let clone = {
		author_id: author.author_id,
		[propertyName]: value
	};

	return getDatahubApi().then((api) =>
		api.patch('/authors/v1/', clone).then(
			() => {
				return {
					author: {
						...author,
						[propertyName]: value
					}
				};
			},
			(fail) => {
				console.error('failed to save author', fail);
				return { message: 'failed to save author' };
			}
		)
	);
};

export const createAuthorSectionFields = (authorName) => {
	return [
		{
			label: 'Section',
			property: 'label',
			render: (value, data, index) => (index === 0 ? <b>{value}</b> : value)
		},
		{
			label: 'Articles',
			property: 'count',
			render: (value, data, index) => {
				return (
					<Link
						className="clickable"
						href={
							index === 0
								? `/media/search?query=${encodeURI(`author:"${authorName}"`)}`
								: `/media/search?query=${encodeURI(`source_section_name:"${data.label}" AND author:"${authorName}"`)}`
						}
						target="_blank"
					>
						{value.toLocaleString()}
					</Link>
				);
			}
		}
	];
};

export const searchAuthors = async (getDatahubApi, queryText) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/authors/v1/search?queryText=${queryText}`)
			.then(({ data }) => {
				return data;
			})
			.catch((e) => handleError(`Failed to get sources`, e))
	);
};
