import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

/**
 * turns the path into crumbs!
 * @param {*} path
 */
const getCrumbs = (path, params, link, redirects) => {
	const parts = path.split('/');

	const crumbs = [];

	let increment = '';

	for (let x = 1; x < parts.length; x++) {
		const currentBit = parts[x].startsWith(':') ? params[parts[x].replace(':', '')] : parts[x];

		increment += x > 0 ? '/' + currentBit : currentBit;

		const path = !isNullOrUndefined(redirects) && !isNullOrUndefined(redirects[increment]) ? redirects[increment] : increment;

		crumbs.push({
			path,
			label: currentBit
		});

		//if the link on the view matches the current, then stop making breadcrumbs
		//this is for tabbed views
		if (!stringIsNullOrEmpty(link) && currentBit === link) {
			break;
		}
	}

	return crumbs;
};

const Header = ({ header, children, breadcrumbs = true, link, redirects }) => {
	const history = useHistory();
	const match = useRouteMatch();
	const [crumbs] = useState(() => getCrumbs(match.path, match.params, link, redirects));

	const renderBreadcrumbs = () => {
		return (
			<Grid item>
				<Breadcrumbs aria-label="breadcrumb">
					{crumbs.map((crumb) => (
						<Link className="link" key={crumb.path} onClick={() => history.push(crumb.path)}>
							{crumb.label}
						</Link>
					))}
				</Breadcrumbs>
			</Grid>
		);
	};

	return (
		<Grid className="header" container>
			{breadcrumbs && renderBreadcrumbs()}
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
					<h1>{header}</h1>
					{children}
				</Inline>
			</Grid>
		</Grid>
	);
};

export default Header;
