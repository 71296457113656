import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError, handleInlineError } from '../../../../../components/Api';

/**
 * removes a workspace scope from a workspace
 * @param {*} param0
 * @param {*} workspace_scope_id
 */
export const removeScopeFromWorkspace = (getDatahubApi, workspace_id, workspace_scope_id) => {
	return getDatahubApi().then((api) =>
		api
			.delete(`/workspaces/v1/scopes/${workspace_id}/${workspace_scope_id}`)
			.then(({ data }) => data)
			.catch((e) => handleInlineError(`Failed to remove feed from workspace`, e))
	);
};

/**
 * Updates the sort order of workspace scope from a workspace
 * @param {*} param0
 * @param {*} workspace_scope_id
 */
export const updateWorkspaceScopeSortOrder = (getDatahubApi, workspace_id, workspace_scope_id, new_sort_order, old_sort_order) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`/workspaces/v1/scope-sort-order`, { workspace_id, workspace_scope_id, new_sort_order, old_sort_order })
			.then(({ data }) => data)
			.catch((e) => ({ message: `Failed to update workspace scope ordering - ${extractError(e)}` }))
	);
};

/**
 * adds scopes to a workspace
 * @param {*} param0
 * @param {*} workspace_id
 * @param {*} scopes list of scope ids
 */
export const addScopesToWorkspace = (getDatahubApi, workspace_id, scopes) => {
	return getDatahubApi().then((api) =>
		api.put(`/workspaces/v1/scopes`, { workspace_id, scopes }).then(({ data }) => {
			const { message, scopes } = data;
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			return scopes;
		})
	);
};
