import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

export const createFieldOptions = () => {
	const filterFunctionsLookup = filterFunctions.reduce((lookup, { function_name, display_name }) => {
		lookup[function_name] = { label: display_name, value: function_name };
		return lookup;
	}, {});

	const medaItemFieldsLookup = mediaItemFields.reduce((lookup, { field_name, display_name, type, group, filter_functions }) => {
		if (isNullOrUndefined(lookup[group])) {
			lookup[group] = [];
		}
		lookup[group].push({
			label: display_name,
			value: field_name,
			type,
			functionOptions: filter_functions.map((filterFunction) => filterFunctionsLookup[filterFunction])
		});
		return lookup;
	}, {});

	return Object.entries(medaItemFieldsLookup).map(([label, options]) => ({
		label,
		options
	}));
};

export const operatorOptions = [
	{
		label: 'Any',
		value: 'should'
	},
	{
		label: 'All',
		value: 'must'
	}
];

export const findOption = (groupedOptions, value) => {
	for (let x = 0; x < groupedOptions.length; x++) {
		const found = groupedOptions[x].options.find((option) => option.value === value);
		if (!isNullOrUndefined(found)) {
			return found;
		}
	}
	return null;
};

const mediaItemFields = [
	{
		group: 'All Fields',
		field_name: 'all_fields',
		display_name: 'All Fields',
		type: 'text',
		filter_functions: ['query_string']
	},
	{
		group: 'Author',
		field_name: 'author_names',
		display_name: 'Author Names',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Author',
		field_name: 'content_author',
		display_name: 'Content Author',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Author',
		field_name: 'influence',
		display_name: 'Influence',
		type: 'integer',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'NLP',
		field_name: 'category_names',
		display_name: 'Category Names',
		type: 'keyword',
		filter_functions: ['not_exists', 'term', 'terms']
	},
	{
		group: 'NLP',
		field_name: 'companies_mentioned',
		display_name: 'Companies Mentioned',
		type: 'object',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'NLP',
		field_name: 'entity_provider',
		display_name: 'Entity Provider',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'NLP',
		field_name: 'locations_mentioned',
		display_name: 'Locations Mentioned',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'NLP',
		field_name: 'people_mentioned',
		display_name: 'People Mentioned',
		type: 'object',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Geography',
		field_name: 'geography_country_ids',
		display_name: 'Country',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Geography',
		field_name: 'geography_city_ids',
		display_name: 'City',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Geography',
		field_name: 'geography_region_ids',
		display_name: 'Region',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Media Item',
		field_name: 'all_fields_meta',
		display_name: 'Full Text',
		type: 'text',
		filter_functions: ['query_string']
	},
	{
		group: 'Media Item',
		field_name: 'accounts_mentioned',
		display_name: 'Accounts Mentioned',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Media Item',
		field_name: 'audience',
		display_name: 'Audience',
		type: 'long',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'body',
		display_name: 'Body',
		type: 'text',
		filter_functions: ['query_string']
	},
	{
		group: 'Media Item',
		field_name: 'duration',
		display_name: 'Duration',
		type: 'integer',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'emoji',
		display_name: 'Emoji',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Media Item',
		field_name: 'found_date',
		display_name: 'Found Date',
		type: 'date',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'hashtags',
		display_name: 'Hashtags',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Media Item',
		field_name: 'item_date',
		display_name: 'Item Date',
		type: 'date',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'item_url',
		display_name: 'Item Url',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Media Item',
		field_name: 'language_code',
		display_name: 'Language',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Media Item',
		field_name: 'modified_date',
		display_name: 'Modified Date',
		type: 'date',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'publication_date',
		display_name: 'Publication Date',
		type: 'date',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'sentiment',
		display_name: 'Sentiment',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Media Item',
		field_name: 'sentiment_magnitude',
		display_name: 'Sentiment Magnitude',
		type: 'double',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'sentiment_score',
		display_name: 'Sentiment Score',
		type: 'double',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Media Item',
		field_name: 'summary',
		display_name: 'Summary',
		type: 'text',
		filter_functions: ['query_string']
	},
	{
		group: 'Media Item',
		field_name: 'title',
		display_name: 'Title',
		type: 'text',
		filter_functions: ['query_string']
	},
	{
		group: 'Media Item',
		field_name: 'word_count',
		display_name: 'Word Count',
		type: 'integer',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Source',
		field_name: 'source_media_type',
		display_name: 'Source Media Type',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Source',
		field_name: 'source_section_id',
		display_name: 'Source Section Id',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Source',
		field_name: 'source_section_name',
		display_name: 'Source Section Name',
		type: 'keyword',
		filter_functions: ['term', 'terms']
	},
	{
		group: 'Source',
		field_name: 'source_source_id',
		display_name: 'Source',
		type: 'keyword',
		filter_functions: ['terms']
	},
	{
		group: 'Source',
		field_name: 'source_source_online_rank',
		display_name: 'Source Online Rank',
		type: 'keyword',
		filter_functions: ['range_gt', 'range_gte', 'range_gte_lte', 'range_lt', 'range_lte']
	},
	{
		group: 'Source',
		field_name: 'source_source_paywalled',
		display_name: 'Source Paywalled',
		type: 'boolean',
		filter_functions: ['term']
	},
	{
		group: 'Source',
		field_name: 'source_source_url',
		display_name: 'Source Url',
		type: 'keyword',
		filter_functions: ['term', 'terms', 'not_exists']
	}
];

const filterFunctions = [
	{
		function_name: 'term',
		display_name: 'Exact Match',
		description: 'Returns documents that contain an exact term in a provided field.'
	},
	{
		function_name: 'terms',
		display_name: 'List',
		description: 'Returns documents that contain one or more exact terms in a provided field.'
	},
	{
		function_name: 'not_exists',
		display_name: 'Not Exists',
		description: 'Returns documents that do not contain an indexed value for a field.'
	},
	{
		function_name: 'query_string',
		display_name: 'Query',
		description: 'Returns documents based on a provided query string, using a parser with a strict syntax.'
	},
	{
		function_name: 'range_gte_lte',
		display_name: 'Range',
		description: 'Returns documents that contain terms within a provided range.'
	},
	{
		function_name: 'range_gt',
		display_name: 'Greater than',
		description: 'Returns documents that contain terms within a provided date range greater than supplied value.'
	},
	{
		function_name: 'range_gte',
		display_name: 'Greater than or equal',
		description: 'Returns documents that contain terms within a provided date range greater than or equal to supplied value.'
	},
	{
		function_name: 'range_lt',
		display_name: 'Less than',
		description: 'Returns documents that contain terms within a provided date range less than supplied value.'
	},
	{
		function_name: 'range_lte',
		display_name: 'Less than or equal',
		description: 'Returns documents that contain terms within a provided date range less than or equal to supplied value.'
	}
];
