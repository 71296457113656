import React, { useCallback, useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import Checkbox from '@truescope-web/react/lib/components/form/Checkbox';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import LoadingIndicator from '@truescope-web/react/lib/components/loading/LoadingIndicator';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../../components/ConfigProvider';
import { addWorkspaceContentRight, removeWorkspaceContentRight } from './api';

const toggleChecked = (items = [], index, item, isChecked) => {
	const newItems = [...items];

	if (!isChecked && items.findIndex(({ contentRightId }) => contentRightId === item.contentRightId) !== -1) {
		newItems.splice(index, 1);
	} else {
		newItems.push(item);
	}

	return newItems;
};

const WorkspaceContentRightsList = ({ workspaceId, workspaceContentRights: workspaceContentRightsProp = [] }) => {
	const { config, setConfig, getContentRights } = useConfig();
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const [workspaceContentRights, setWorkspaceContentRights] = useState(workspaceContentRightsProp);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!isNullOrUndefined(config.contentRights)) {
			return;
		}

		setIsLoading(true);
		getContentRights(getDatahubApi)
			.then((contentRights) => {
				setConfig((prev) => ({
					...prev,
					contentRights
				}));
			})
			.finally(() => setIsLoading(false));
	}, [config.contentRights]);

	const handleToggleCheckbox = useCallback(
		(index, contentRight, isCheckedValue) => {
			setIsLoading(true);

			if (!isCheckedValue) {
				removeWorkspaceContentRight(getDatahubApi, workspaceId, contentRight.workspaceContentRightId)
					.then(() => setWorkspaceContentRights((prev) => toggleChecked(prev, index, contentRight, isCheckedValue)))
					.catch(() => {
						showSnackbar('Failed to remove content rights from this workspace', snackbarVariants.error);
					})
					.finally(() => setIsLoading(false));
				return;
			}

			addWorkspaceContentRight(getDatahubApi, workspaceId, contentRight.contentRightId)
				.then((item) =>
					setWorkspaceContentRights((prev) =>
						toggleChecked(prev, index, { contentRightId: contentRight.contentRightId, ...item }, isCheckedValue)
					)
				)
				.catch(() => {
					showSnackbar('Failed to add content rights to this workspace', snackbarVariants.error);
				})
				.finally(() => setIsLoading(false));
		},
		[setIsLoading, setWorkspaceContentRights, getDatahubApi]
	);

	return (
		<List className="workspace-content-rights__list">
			{isLoading && (
				<div className="workspace-content-rights__list__loading-overlay">
					<LoadingIndicator />
				</div>
			)}

			{arrayIsNullOrEmpty(config?.contentRights) ? (
				<ListItem>
					<Typography>There are no content rights to display</Typography>
				</ListItem>
			) : (
				config.contentRights.map((right) => {
					const workspaceContentRightIndex = workspaceContentRights.findIndex(
						(workspaceRight) => workspaceRight.contentRightId === right.contentRightId
					);

					return (
						<ListItem key={right.contentRightId} disableGutters className="workspace-content-rights__list__item">
							<ListItemIcon>
								<Checkbox
									checked={workspaceContentRightIndex !== -1}
									onChange={(_event, isCheckedValue) =>
										handleToggleCheckbox(
											workspaceContentRightIndex,
											isCheckedValue ? right : workspaceContentRights[workspaceContentRightIndex],
											isCheckedValue
										)
									}
								/>
							</ListItemIcon>
							<Tooltip arrow title={right.description}>
								<Typography>{right.name}</Typography>
							</Tooltip>
						</ListItem>
					);
				})
			)}
		</List>
	);
};

export default WorkspaceContentRightsList;
