import { mdiFacebook, mdiLinkedin, mdiTwitter } from '@mdi/js';
import Icon from '@mdi/react';
import cloneDeep from 'lodash.clonedeep';
import React, { useState } from 'react';
import Add from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNew from '@mui/icons-material/OpenInNewOutlined';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@truescope-web/react/lib/components/form/Button';
import ClickToEditField from '@truescope-web/react/lib/components/form/ClickToEditField';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Avatar from '@truescope-web/react/lib/components/widgets/Avatar';
import ImageBadge from '@truescope-web/react/lib/components/widgets/ImageBadge';
import SourceLogo from '@truescope-web/react/lib/components/widgets/SourceLogo';
import { validationResults, validationScore } from '@truescope-web/react/lib/utils/validation';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { isValidUrl, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../ApiLookupProvider';
import { createSocialNetworkHandle, deleteSocialNetworkHandle, updateSocialNetworkHandle } from './SocialNetworkHandleEditorConstants';

const iconMap = {
	Facebook: mdiFacebook,
	Twitter: mdiTwitter,
	LinkedIn: mdiLinkedin
};

const SocialIcon = (props) => {
	if (!isNullOrUndefined(props.imageSrc) && !isNullOrUndefined(props.icon)) {
		const svg =
			'data:image/svg+xml;charset=UTF-8,' +
			encodeURI(
				`<svg height="12" width="12" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d='${props.icon}'></path></svg>`
			);

		return (
			<ImageBadge imgUrl={svg} alt={props.name}>
				<Avatar imgSrc={props.imageSrc} alt={props.name} size="sm" initials={props.name}></Avatar>
			</ImageBadge>
		);
	}

	if (!isNullOrUndefined(props.icon)) {
		return <Icon path={props.icon} size="48px" />;
	}

	return <SourceLogo sourceUrl={props.url} sourceName={props.name} tooltip={props.name} avatarOnly />;
};

/**
 * takes a list of social networks, turns them into a lookup and assigns them a nice icon
 * @param {*} socialNetworks
 */
const createSocialNetworkLookup = (socialNetworks) => {
	return (socialNetworks || []).reduce((lookup, socialNetwork) => {
		lookup[socialNetwork.social_network_id] = socialNetwork;

		const icon = iconMap[socialNetwork.name];
		if (!isNullOrUndefined(icon)) {
			socialNetwork.icon = icon;
		}

		return lookup;
	}, {});
};

const SocialNetworkHandleEditor = ({ metadata, sourceReference, sourceReferenceType, onChange, shouldSave = true }) => {
	const [getDatahubApi] = useApiLookup();

	const socialNetworkHandles = metadata?.social_network_handles;
	const socialNetworks = metadata?.social_networks;

	const [socialNetworkLookup] = useState(createSocialNetworkLookup(socialNetworks));
	const [anchorEl, setAnchorEl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [validationState, setValidationState] = useState({});

	const handleSelectSocialNetworkHandle = (url) => {
		const newWindow = window.open(url, '_blank');
		if (newWindow) {
			newWindow.opener = null;
		}
	};

	const handleUpdateSocialNetworkUrl = (index, socialNetworkHandle, url) => {
		let func;

		const socialNetworkUrl = socialNetworkLookup[socialNetworkHandle.social_network_id].url;
		if (stringIsNullOrEmpty(url) || !isValidUrl(url)) {
			onChange((prev) => {
				const socialNetworkHandles = prev.social_network_handles;
				socialNetworkHandles[index] = { ...socialNetworkHandle, url };
				return { ...prev, social_network_handles: socialNetworkHandles };
			});

			setValidationState((prev) => ({
				...prev,
				[index]: validationResults.error(`Please enter a valid url beginning with ${socialNetworkUrl}`)
			}));
			return;
		} else {
			setValidationState((prev) => ({
				...prev,
				[index]: validationResults.ok()
			}));
		}

		if (!shouldSave) {
			onChange((prev) => {
				const socialNetworkHandles = prev.social_network_handles;
				socialNetworkHandles[index] = { ...socialNetworkHandle, url };
				return { ...prev, social_network_handles: socialNetworkHandles };
			});
			return;
		}

		if (isNullOrUndefined(socialNetworkHandle.social_network_handle_id)) {
			let cloned = cloneDeep(socialNetworkHandle);
			cloned.url = url;
			func = () => createSocialNetworkHandle(getDatahubApi, cloned);
		} else {
			func = () => updateSocialNetworkHandle(getDatahubApi, socialNetworkHandle, 'url', url);
		}

		setIsLoading(true);
		func()
			.then((data) => {
				const socialNetworkHandles = metadata?.social_network_handles;
				if (!isNullOrUndefined(data.socialNetworkHandle)) {
					socialNetworkHandles[index] = { ...socialNetworkHandles[index], ...data.socialNetworkHandle };
				} else {
					socialNetworkHandles[index].url = url;
				}
				onChange((prev) => ({ ...prev, social_network_handles: socialNetworkHandles }));
			})
			.finally(() => setIsLoading(false));
	};

	const handleRemoveHandle = (index, socialNetworkHandle) => {
		setIsLoading(true);
		deleteSocialNetworkHandle(getDatahubApi, socialNetworkHandle)
			.then(() => {
				const socialNetworkHandles = metadata?.social_network_handles;
				socialNetworkHandles.splice(index, 1);
				onChange((prev) => ({ ...prev, social_network_handles: socialNetworkHandles }));
			})
			.finally(() => setIsLoading(false));
	};

	const handleShowHandleMenu = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseHandleMenu = () => {
		setAnchorEl(null);
	};

	const handleAddHandle = (socialNetwork) => {
		handleCloseHandleMenu();
		const socialNetworkHandles = metadata?.social_network_handles || [];

		socialNetworkHandles.push({
			social_network_handle_id: null,
			url: null,
			source_reference_type_id: sourceReferenceType,
			source_reference: sourceReference,
			social_network_id: socialNetwork.social_network_id
		});

		onChange((prev) => ({ ...prev, social_network_handles: socialNetworkHandles }));
	};

	const renderSocialNetworkHandles = () => {
		return (
			<Grid container spacing={1}>
				{(socialNetworkHandles || []).map((socialNetworkHandle, index) => {
					const socialNetwork = socialNetworkLookup[socialNetworkHandle.social_network_id];

					if (isNullOrUndefined(socialNetwork)) {
						return null;
					}

					const hasError = validationState[index]?.score === validationScore.error;

					const layout = [
						<Grid
							item
							key={`${socialNetworkHandle.url}-${socialNetworkHandle.social_network_id}-${socialNetworkHandle?.social_network_handle_id}`}
						>
							<Inline>
								<SocialIcon
									url={socialNetwork.url}
									name={socialNetwork.name}
									icon={socialNetwork.icon}
									imageSrc={socialNetworkHandle.image_src}
								/>
								<ClickToEditField
									value={socialNetworkHandle.url}
									loading={isLoading}
									onAccept={(url) =>
										handleUpdateSocialNetworkUrl(index, { ...socialNetworkHandle, url: socialNetworkHandle.url }, url)
									}
									placeholder="No handle specified"
								/>
								{!stringIsNullOrEmpty(socialNetworkHandle.url) && !hasError && (
									<Tooltip title="Visit Link" aria-label="Visit Link">
										<IconButton size="small" onClick={() => handleSelectSocialNetworkHandle(socialNetworkHandle.url)}>
											<OpenInNew />
										</IconButton>
									</Tooltip>
								)}

								<Tooltip title={`Remove Handle`} aria-label={`Remove Handle`}>
									<IconButton size="small" onClick={() => handleRemoveHandle(index, socialNetworkHandle)}>
										<CloseIcon />
									</IconButton>
								</Tooltip>
							</Inline>
						</Grid>
					];

					if (hasError) {
						layout.push(
							<Grid
								item
								key={`${socialNetworkHandle.url}-${socialNetworkHandle.social_network_id}-${socialNetworkHandle?.social_network_handle_id}-error`}
							>
								<ValidationPlaceholder validationResult={validationState[index]} />
							</Grid>
						);
					}

					return layout;
				})}
				<Grid item>
					<Button
						fab={<Add />}
						tooltip="Add"
						aria-controls="handle-menu"
						aria-haspopup="true"
						onClick={handleShowHandleMenu}
					></Button>
					<Menu id="handle-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseHandleMenu}>
						{(socialNetworks || []).map((socialNetwork, index) => (
							<MenuItem key={index} onClick={() => handleAddHandle(socialNetwork)}>
								<Typography variant="h6">{socialNetwork.name}</Typography>
							</MenuItem>
						))}
					</Menu>
				</Grid>
			</Grid>
		);
	};

	return renderSocialNetworkHandles();
};

export default SocialNetworkHandleEditor;
