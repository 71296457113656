import moment from 'moment';
import React, { createContext, useCallback } from 'react';
import { createApi } from '@truescope-web/utils/lib/api';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import config from '../config.json';
import { useGoogleAuthContext } from './GoogleAuth/GoogleAuthProvider';
import { getAuthState, getRefreshToken, sleep } from './GoogleAuth/constants';
import { updateToken } from './GoogleAuth/reducer';

const ApiLookupContext = createContext({});

const maxAttempts = 3;

/**
 * contains the states for the api lookup
 * @param {*} param0
 */
export const ApiLookupProvider = ({ children }) => {
	const [{ isAuthenticated }, dispatchGoogleAuthContext, logout] = useGoogleAuthContext();

	const tryRefreshToken = useCallback(
		async (currentRefreshToken) => {
			let attempt = 1;
			while (attempt++ < maxAttempts) {
				try {
					const { accessToken, expiry, refreshToken, user } = await getRefreshToken({ refreshToken: currentRefreshToken });
					dispatchGoogleAuthContext(updateToken(refreshToken, accessToken, moment(expiry).toISOString(false), user));
					return accessToken;
				} catch (e) {
					await sleep(2000);
				}
			}

			console.error(`failed to refresh token after ${maxAttempts} attempts`);
			logout();
		},
		[dispatchGoogleAuthContext, logout]
	);

	const getToken = useCallback(async () => {
		if (!isAuthenticated) {
			throw new Error('Not authenticated');
		}

		const { refreshToken, accessToken, expiry } = getAuthState();
		if (isNullOrUndefined(expiry)) {
			throw new Error(`No token expiry`);
		}

		//giving this a buffer of 1 min, in case there's extra stuff that happens and the token somehow expires
		const minutesUntilExpiry = moment(expiry).diff(moment(), 'minutes');
		if (minutesUntilExpiry < 1) {
			return await tryRefreshToken(refreshToken);
		}

		return accessToken;
	}, [isAuthenticated, tryRefreshToken]);

	const getDatahubApi = useCallback(async () => {
		const token = await getToken();
		return createApi(config.api.datahub.baseUrl, { token });
	}, [getToken]);

	return <ApiLookupContext.Provider value={[getDatahubApi]}>{children}</ApiLookupContext.Provider>;
};

export const useApiLookup = () => {
	const context = React.useContext(ApiLookupContext);
	if (context === undefined) {
		throw new Error('getDatahubApi must be used within a ApiLookupProvider');
	}
	return context;
};
