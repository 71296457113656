import { handleInlineError } from '../../../../../components/Api';

/**
 * toggles user activation
 * @param {*} getDatahubApi
 * @param {*} workspaceUserId
 * @param {*} workspaceUserStateId
 */
export const setUserState = (getDatahubApi, workspaceUserId, workspaceUserStateId, userId, workspaceId) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`/workspaces/v1/workspace_user`, { workspaceUserId, workspaceUserStateId, userId, workspaceId })
			.then(({ data }) => data)
			.catch((e) => handleInlineError(`Failed to set user state`, e))
	);
};

/**
 * sends invites to a list of emails
 * @param {*} param0
 * @param {*} workspace_id
 * @param {*} emails
 */
export const sendInvites = (getDatahubApi, workspace_id, emails, role_id) => {
	return getDatahubApi().then((api) =>
		api
			.post(`/workspaces/v1/invites`, { workspace_id, emails, role_id })
			.then(({ data }) => data)
			.catch((e) => handleInlineError(`Failed to send invites`, e))
	);
};

/**
 * revokes an invite for the given email
 * @param {*} getDatahubApi - the reference to the api layer
 * @param {*} workspace_id - the workspace the user email is associated with
 * @param {*} email - the email reference to delete from pending invites
 */
export const removeInvite = (getDatahubApi, workspace_id, email) => {
	return getDatahubApi().then((api) =>
		api
			.delete(`/workspaces/v1/${workspace_id}/invites/${email}`)
			.then(({ data }) => data)
			.catch((e) => handleInlineError(`Failed to remove invites`, e))
	);
};
