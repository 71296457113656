import React, { useEffect, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Typography as MUTypography, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import LoadingIndicator from '@truescope-web/react/lib/components/loading/LoadingIndicator';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { getSelectedEntity, getSelectedOptionById, queryTableFields } from './MediaItemConstants';
import QueryOptions from './QueryOptions';

const QueriesPanel = ({ queries: queriesData, config, onSave, selectOptions, isLoading }) => {
	const [queries, setQueries] = useState(queriesData);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		setQueries(queriesData);
	}, [queriesData]);

	const handleOnSave = (query) => {
		const queriesClone = [...queries];
		queriesClone.push(getSelectedEntity(query.query_id, config.queryOptions, queriesClone, 'query', true));
		onSave(queriesClone);
	};

	const handleRemoveQuery = (queryIndex) => {
		setAnchorEl(null);
		const queriesClone = [...queries];
		queriesClone.splice(queryIndex, 1);
		onSave(queriesClone, false);
	};

	const renderEmptyState = () => (
		<TableBody>
			<TableRow>
				<TableCell>This item has not been matched to any {queryTableFields[0].label}.</TableCell>
			</TableRow>
		</TableBody>
	);

	const handleOpenMenu = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<div className="queries-panel">
			<Table className="table table--panel">
				{arrayIsNullOrEmpty(queries) ? (
					renderEmptyState()
				) : (
					<>
						<TableHead>
							<TableRow>
								<TableCell>Query</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{queries.map((query, index) => {
								const queryData = getSelectedOptionById(query.query_id, config.queryOptions);

								return isNullOrUndefined(queryData) ? null : (
									<TableRow className="queries-item" key={`${query.query_id}-${query.name}`}>
										<TableCell>{`${query.name} - ${queryData.metadata.workspace_name}`}</TableCell>

										<TableCell align="right">
											<IconButton aria-controls="row-menu" aria-haspopup="true" onClick={handleOpenMenu}>
												<MoreVert />
											</IconButton>
											<Menu
												id="row-menu"
												anchorEl={anchorEl}
												open={!isNullOrUndefined(anchorEl)}
												onClose={handleCloseMenu}
											>
												<MenuItem onClick={() => handleRemoveQuery(index)}>
													<MUTypography>Remove</MUTypography>
												</MenuItem>
											</Menu>
										</TableCell>
									</TableRow>
								);
							})}

							{isLoading && (
								<TableRow>
									<TableCell colSpan={2}>
										<LoadingIndicator />
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</>
				)}
			</Table>

			<QueryOptions handleOnSave={handleOnSave} selectOptions={selectOptions} />
		</div>
	);
};

export default QueriesPanel;
