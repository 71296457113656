import moment from 'moment';
import Lock from '@mui/icons-material/LockOutlined';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

export const lockAdornment = {
	position: 'end',
	icon: <Lock />
};

export const workspaceValidationRules = {
	plan_start_date: ({ plan_start_date, plan_end_date }) => {
		if (stringIsNullOrEmpty(plan_start_date)) {
			return validationResults.required();
		}
		if (!stringIsNullOrEmpty(plan_end_date) && moment(plan_end_date).isBefore(moment(plan_start_date))) {
			return validationResults.required(`'plan start date' must occur before 'plan end date'`);
		}
		return validationResults.ok();
	},
	plan_end_date: ({ plan_start_date, plan_end_date }) => {
		if (stringIsNullOrEmpty(plan_end_date)) {
			return validationResults.required();
		}
		if (!stringIsNullOrEmpty(plan_start_date) && moment(plan_end_date).isBefore(moment(plan_start_date))) {
			return validationResults.required(`Must occur after 'plan start date'`);
		}
		return validationResults.ok();
	}
};
