import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { handleError } from '../Api';

export const updateSocialNetworkHandle = (getDatahubApi, socialNetworkHandle, propertyName, value) => {
	let clone = {
		social_network_handle_id: socialNetworkHandle.social_network_handle_id,
		s3_object_key: socialNetworkHandle.s3_object_key,
		name: socialNetworkHandle.name,
		[propertyName]: value
	};

	return getDatahubApi().then((api) =>
		api
			.patch('/handles/v1/', clone)
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to update social network handle ${socialNetworkHandle.social_network_handle_id}`, e, {}))
	);
};

export const createSocialNetworkHandle = (getDatahubApi, socialNetworkHandle) => {
	return getDatahubApi().then((api) =>
		api
			.put('/handles/v1/', [socialNetworkHandle])
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to create social network handle`, e, {}))
	);
};

export const createSocialNetworkHandles = (getDatahubApi, socialNetworkHandles) => {
	if (arrayIsNullOrEmpty(socialNetworkHandles)) {
		return;
	}
	return getDatahubApi().then((api) =>
		api
			.put('/handles/v1/', socialNetworkHandles)
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to create social network handle`, e, {}))
	);
};

export const deleteSocialNetworkHandle = (getDatahubApi, { social_network_handle_id }) => {
	if (isNullOrUndefined(social_network_handle_id)) {
		return Promise.resolve(true);
	}

	return getDatahubApi().then((api) =>
		api
			.delete(`/handles/v1/${social_network_handle_id}`)
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to delete social network handle ${social_network_handle_id}`, e, {}))
	);
};
