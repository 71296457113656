import React from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const LastUpdated = ({ name, date }) => {
	if (isNullOrUndefined(date)) {
		return;
	}

	const convertUTCDateToLocalDate = (stringDate) => {
		const date = new Date(stringDate);
		var offset = new Date().getTimezoneOffset() / 60;
		var hours = date.getHours();
		date.setHours(hours - offset);
		return date.toLocaleString();
	};

	const formattedDate = convertUTCDateToLocalDate(date);

	return (
		<Typography style={{ paddingTop: '20px', paddingBottom: '20px' }} variant="subtitle">
			{isNullOrUndefined(name) ? `Updated on ${formattedDate}` : `Updated by ${name} on ${formattedDate}`}
		</Typography>
	);
};

export default LastUpdated;
