import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import Button from '@truescope-web/react/lib/components/form/Button';
import ClickToEditField from '@truescope-web/react/lib/components/form/ClickToEditField';
import Accordion from '@truescope-web/react/lib/components/layout/Accordion';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import DebugView from '@truescope-web/react/lib/components/widgets/DebugView';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { sourceReferenceTypes } from '@truescope-web/utils/lib/entityHelpers';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { isValidUrl, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { getAuthState } from '../../../components/GoogleAuth/constants';
import Header from '../../../components/Header';
import LastUpdated from '../../../components/LastUpdated';
import SocialNetworkHandleEditor from '../../../components/Widgets/SocialNetworkHandleEditor';
import { createSocialNetworkHandles } from '../../../components/Widgets/SocialNetworkHandleEditorConstants';
import About from './About';
import Audiences from './Audiences';
import {
	createChannel,
	createSourceAudienceFields,
	getMediaTypes,
	getSocialNetworks,
	getSource,
	getSourceGeography,
	updateSource,
	updateSourceGeography
} from './SourceConstants';
import SourceGeography from './SourceGeography';
import SourceLogoUploader from './SourceLogoUploader';

const Source = ({ createMode = false, source_id: sid }) => {
	const { showSnackbar } = useSnackbar();
	const { user } = getAuthState();
	const match = useRouteMatch();
	const [getDatahubApi] = useApiLookup();
	const [isCreateMode, setIsCreateMode] = useState(createMode);
	const [sourceId, setSourceId] = useState(!stringIsNullOrEmpty(sid) ? sid : match?.params?.source_id || null);
	const [metadata, setMetadata] = useState(null);
	const [source, setSource] = useState(null);
	const [audienceFields, setAudienceFields] = useState(null);
	const [mediaTypes, setMediaTypes] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [areSocialNetworksValid, setAreSocialNetworksValid] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [sourceGeography, setSourceGeography] = useState(null);

	const getSourceDetails = useCallback(async () => {
		const { metadata, source, mediaTypes } = await getSource(getDatahubApi, sourceId);
		setMetadata(metadata);
		setSource(source);
		setAudienceFields(createSourceAudienceFields(source));
		setMediaTypes(mediaTypes);
		const newSourceGeography = await getSourceGeography(getDatahubApi, sourceId);
		setSourceGeography(newSourceGeography);
	}, [getDatahubApi, sourceId, setMetadata, setSource, setAudienceFields, setMediaTypes]);

	useEffect(() => {
		const loadData = async () => {
			try {
				setIsLoading(true);
				if (isCreateMode) {
					const loadedMediaTypes = await getMediaTypes(getDatahubApi);
					setMediaTypes(loadedMediaTypes.mediaTypes);
					setSource({});
				}
				if (!stringIsNullOrEmpty(sourceId) && sourceId !== source?.source_id) {
					await getSourceDetails();
					setIsCreateMode(false);
				} else {
					const socialNetworks = await getSocialNetworks(getDatahubApi);
					setMetadata({ social_networks: socialNetworks });
				}
			} catch (e) {
				const msg = `failed to load channel - ${e.message}`;
				console.error(msg, e);
				showSnackbar(msg, snackbarVariants.error);
			} finally {
				setIsLoading(false);
			}
		};
		loadData();
	}, [getDatahubApi, setMediaTypes, setSource, sourceId, isCreateMode, setIsCreateMode]);

	useEffect(() => {
		if (!isCreateMode || arrayIsNullOrEmpty(metadata?.social_network_handles)) {
			return;
		}
		setAreSocialNetworksValid(metadata.social_network_handles.every((socialNetwork) => isValidUrl(socialNetwork.url)));
	}, [isCreateMode, metadata]);

	const isSourceValidated = (newSourceName) => {
		if (stringIsNullOrEmpty(newSourceName)) {
			showSnackbar('Channel name is required', snackbarVariants.error);
		}

		return !stringIsNullOrEmpty(newSourceName);
	};

	const handleUpdateSource = useCallback(
		async (propertyName, value, oldSource = null) => {
			try {
				setIsPartialLoading(true);
				if (isCreateMode) {
					if (!isNullOrUndefined(oldSource)) {
						setSource(oldSource);
					} else {
						setSource((prev) => ({ ...prev, [propertyName]: value }));
					}
				} else if (propertyName === 'name' && !isSourceValidated(value)) {
					return;
				}

				if (!isNullOrUndefined(oldSource?.media_type_id) || !isNullOrUndefined(source?.media_type_id)) {
					const newSource = oldSource || source;
					const { message: updatedSourceMessage, source: updatedSource } = await updateSource(getDatahubApi, newSource, {
						[propertyName]: value
					});
					if (!stringIsNullOrEmpty(updatedSourceMessage)) {
						throw new Error(updatedSourceMessage);
					}
					setSource(updatedSource);
				}

				if (!isCreateMode) {
					showSnackbar(`Channel has been successfully updated.`, snackbarVariants.success);
				}
			} catch (e) {
				const msg = `failed to updated source - ${e.message}`;
				console.error(msg, e);
				showSnackbar(msg, snackbarVariants.error);
			} finally {
				setIsPartialLoading(false);
			}
		},
		[isCreateMode, setSource, source, setIsPartialLoading, getDatahubApi, updateSource]
	);

	const handleUpdateSourceGeography = useCallback(
		async (updatedSourceGeography, isInitialization) => {
			try {
				setIsPartialLoading(true);
				setSourceGeography(updatedSourceGeography);
				if (isCreateMode || isInitialization) {
					return;
				}
				const { message: createUpdateSourceGeographyMessage } = await updateSourceGeography(
					getDatahubApi,
					updatedSourceGeography,
					sourceId
				);
				if (!stringIsNullOrEmpty(createUpdateSourceGeographyMessage)) {
					throw new Error(createUpdateSourceGeographyMessage);
				}
			} catch (e) {
				showSnackbar(e.message, snackbarVariants.error, null);
				const msg = `failed to updated source geography - ${e.message}`;
				console.error(msg, e);
			} finally {
				setIsPartialLoading(false);
			}
		},
		[isCreateMode, setIsPartialLoading, setSourceGeography, getDatahubApi]
	);

	const handleCreateChannel = async () => {
		if (isNullOrUndefined(source.name) || isNullOrUndefined(source.media_type_id)) {
			showSnackbar('Channel name and media type are required fields.', snackbarVariants.error);
			return;
		}

		try {
			const { item: newSource, message: newSourceMessage } = await createChannel(getDatahubApi, source, user.email);
			if (!stringIsNullOrEmpty(newSourceMessage)) {
				throw new Error(newSourceMessage);
			}

			if (!isNullOrUndefined(sourceGeography)) {
				await updateSourceGeography(getDatahubApi, sourceGeography, newSource.source_id);

				showSnackbar(`We couldn't add the geography to this channel, please add it again`, snackbarVariants.error);
			}

			if (!arrayIsNullOrEmpty(metadata.social_network_handles)) {
				await createSocialNetworkHandles(
					getDatahubApi,
					metadata.social_network_handles.map((social) => ({ ...social, source_reference: newSource.source_id }))
				);
				showSnackbar(`We couldn't add any social networks to this channel, please try again`, snackbarVariants.error);
			}

			setSourceId(newSource.source_id);
			setIsCreateMode(false);
			showSnackbar(`Channel ${source.name} has been created successfully.`, snackbarVariants.success);
		} catch (e) {
			const msg = `failed to create channel - ${e.message}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
		}
	};

	const getPanelItems = (metadata) => {
		const contentPlaceholder = 'Please create the channel first';
		const panelItems = {
			About: {
				label: 'About',
				content: (
					<>
						<About
							source={source}
							setSource={setSource}
							setErrorMessage={setErrorMessage}
							mediaTypes={mediaTypes}
							handleUpdateSource={handleUpdateSource}
							isCreateMode={isCreateMode}
						/>
						<hr />
						<div className="source-social-handles">
							<Typography>Social Handles</Typography>
						</div>
						<SocialNetworkHandleEditor
							metadata={metadata}
							onChange={setMetadata}
							sourceReference={source?.source_id}
							sourceReferenceType={sourceReferenceTypes.sources}
							shouldSave={!isCreateMode}
						/>
					</>
				)
			},
			Geography: {
				label: 'Geography',
				content: <SourceGeography sourceGeography={sourceGeography} onChange={handleUpdateSourceGeography} />
			},
			Audiences: { label: 'Audiences', content: contentPlaceholder },
			JSON: { label: 'JSON', content: contentPlaceholder }
		};

		if (!isCreateMode) {
			panelItems.Audiences.content = (
				<Audiences
					source={source}
					audiences={metadata.audiences}
					audienceFields={audienceFields}
					setAudienceFields={setAudienceFields}
				/>
			);
			const json = {
				author: source,
				metadata
			};

			panelItems.JSON.content = <DebugView object={json} />;
		}

		return Object.values(panelItems).map((item) => ({ label: item.label, content: item.content }));
	};

	if (isNullOrUndefined(source) || isLoading) {
		return (
			<div className="full-width">
				<SkeletonWrapper />
			</div>
		);
	}

	if (!stringIsNullOrEmpty(errorMessage)) {
		return (
			<Grid container>
				<Grid item>{errorMessage}</Grid>
			</Grid>
		);
	}

	if (!isCreateMode && isNullOrUndefined(metadata)) {
		return (
			<Grid container>
				<Grid item>Failed to load metadata</Grid>
			</Grid>
		);
	}

	const panelItems = getPanelItems(metadata);

	return (
		<Grid container>
			{isCreateMode && <Header breadcrumbs={false} header={'Add Channel'} />}
			<Grid item>
				<Inline>
					<SourceLogoUploader source={source} onChange={(logoUrl) => handleUpdateSource('override_profile_image_url', logoUrl)} />
					<ClickToEditField
						placeholder="Channel Name"
						value={source?.name}
						fullWidth={false}
						typographyVariant="h4"
						loading={isPartialLoading}
						onAccept={(value) => handleUpdateSource('name', value)}
					/>
				</Inline>
			</Grid>

			<Grid item>
				<Accordion initialExpandedIndex={panelItems.length > 1 ? 0 : -1} items={panelItems} />
				<LastUpdated name={source?.user_context?.name} date={source?.date_modified_utc} />
			</Grid>

			{isCreateMode && (
				<Grid item>
					<Inline horizontalAlignment={horizontalAlignment.right}>
						<Button
							startIcon={<Add />}
							disabled={!areSocialNetworksValid}
							tooltip="Add Channel"
							onClick={handleCreateChannel}
							variant="primary"
						>
							Add Channel
						</Button>
					</Inline>
				</Grid>
			)}
		</Grid>
	);
};

export default Source;
