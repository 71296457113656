import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@truescope-web/react/lib/components/form/Button';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { workspaceUserStatesLookup } from '@truescope-web/utils/lib/workspaces';
import { useConfig } from '../../components/ConfigProvider';

const ClientUserWorkspacesTable = ({ user, setUser }) => {
	const { config } = useConfig();
	const [selectedWorkspacesIds, setSelectedWorkspacesIds] = useState([]);
	const [showDeactivateAlert, setShowDeactivateAlert] = useState(false);

	const activeNumber = user.workspaces.filter(
		({ workspace_id, workspace_user_state_id }) =>
			selectedWorkspacesIds.includes(workspace_id) && workspace_user_state_id === workspaceUserStatesLookup.active
	).length;

	const workspacesTableRows = user?.workspaces.map(({ workspace_id, name, role_id, workspace_user_state_id }) => ({
		id: workspace_id,
		name,
		role_id,
		workspace_user_state_id
	}));

	const handleUpdateSingleWorkspace = ({ row, value }, key) => {
		const updatedWorkspaces = [...user.workspaces];
		const updatedWorkspaceIndex = updatedWorkspaces.findIndex(({ workspace_id }) => workspace_id === row.id);

		row[key] = value;
		updatedWorkspaces[updatedWorkspaceIndex][key] = value;

		setUser((prev) => ({ ...prev, workspaces: updatedWorkspaces }));
		return row;
	};

	const workspacesTableColumns = [
		{ field: 'name', headerName: 'Workspace', width: 180 },
		{
			field: 'role_id',
			headerName: 'User role',
			width: 250,
			type: 'singleSelect',
			editable: true,
			valueOptions: config.roleOptions || [],
			valueFormatter: ({ value }) => (config.roleOptions || []).find((role) => role.value === value)?.label,
			valueSetter: ({ row, value }) => handleUpdateSingleWorkspace({ row, value }, 'role_id')
		},
		{
			field: 'workspace_user_state_id',
			headerName: 'Status',
			width: 100,
			renderCell: ({ value }) =>
				value === workspaceUserStatesLookup.active ? (
					<Notification hideIcon center variant="info">
						Active
					</Notification>
				) : (
					<Notification hideIcon center variant="disabled">
						Inactive
					</Notification>
				)
		}
	];

	const handleUpdateWorkspaceUserState = (stateId) => {
		if (showDeactivateAlert) {
			setShowDeactivateAlert(false);
		}

		const updatedWorkspaces = user.workspaces;
		selectedWorkspacesIds.forEach((selectedId) => {
			const selectedWorkspaceIndex = updatedWorkspaces.findIndex(({ workspace_id }) => workspace_id === selectedId);
			updatedWorkspaces[selectedWorkspaceIndex].workspace_user_state_id = stateId;
		});
		setUser((prev) => ({ ...prev, workspaces: updatedWorkspaces }));
		setSelectedWorkspacesIds([]);
	};

	return (
		<div className="user-workspaces-table" id="user-workspaces-table">
			<Button
				variant="text"
				onClick={() => handleUpdateWorkspaceUserState(workspaceUserStatesLookup.active)}
				disabled={arrayIsNullOrEmpty(selectedWorkspacesIds) || activeNumber === selectedWorkspacesIds.length}
			>
				Activate
			</Button>
			<Button
				variant="text"
				onClick={() => setShowDeactivateAlert(true)}
				disabled={arrayIsNullOrEmpty(selectedWorkspacesIds) || activeNumber === 0}
			>
				Deactivate
			</Button>
			<DataGrid
				rows={workspacesTableRows}
				columns={workspacesTableColumns}
				checkboxSelection
				onSelectionModelChange={setSelectedWorkspacesIds}
				selectionModel={selectedWorkspacesIds}
				disableSelectionOnClick
				autoHeight
				hideFooterPagination
				disableColumnMenu
				density="comfortable"
				isCellEditable={({ field, row }) =>
					field === 'workspace_user_state_id' ||
					(field === 'role_id' && row.workspace_user_state_id !== workspaceUserStatesLookup.inactive)
				}
			/>
			<Alert
				title="Warning"
				renderContent={() => (
					<div>
						<Typography variant="subtitle">
							Deactivated user will lose all of their Dashboard, Report and Alert permissions.
						</Typography>
						<Typography variant="subtitle">Would you like to deactivate this user?</Typography>
					</div>
				)}
				cancelLabel="No"
				acceptLabel="Yes"
				open={showDeactivateAlert}
				handleCancel={() => setShowDeactivateAlert(false)}
				handleAccept={() => handleUpdateWorkspaceUserState(workspaceUserStatesLookup.inactive)}
			/>
		</div>
	);
};

export default ClientUserWorkspacesTable;
