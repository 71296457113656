import DataGrid, { Column, FilterRow, HeaderFilter, RemoteOperations, Scrolling } from 'devextreme-react/data-grid';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Button from '@truescope-web/react/lib/components/form/Button';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import SourceLogo from '@truescope-web/react/lib/components/widgets/SourceLogo';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { convertItems, createDataSource, renderPlaintextCell } from '../../../components/Dx/DxGridConstants';
import Header from '../../../components/Header';
import { useDataGridHeight } from '../../../hooks/useWindowHeight';
import Source from './Source';

const Sources = () => {
	const [getDatahubApi] = useApiLookup();
	const [showSourceSheet, setShowSourceSheet] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [mediaTypeFilters, setMediaTypeFilters] = useState(null);
	const [mediaTypes, setMediaTypes] = useState(null);
	const [selected, setSelected] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [create, setCreate] = useState(false);

	const history = useHistory();
	const gridHeight = useDataGridHeight();

	const handleSearchStart = () => {
		setIsLoading(true);
	};

	const handleSearchComplete = (_e) => {
		if (!isNullOrUndefined(dataSource.cache.filters)) {
			setMediaTypes(dataSource.cache.filters.mediaTypes.store);
			setMediaTypeFilters(dataSource.cache.filters.mediaTypes.store.map((mt) => ({ text: mt.text, value: mt.text })));
		}
		setIsLoading(false);
	};

	const [dataSource, onChange] = useState(() =>
		createDataSource({
			getDatahubApi,
			requestPath: '/sources/v1',
			onSearchStart: handleSearchStart,
			onSearchComplete: handleSearchComplete,
			convertItems,
			filterInclusions: ['media_types'],
			useWhereClauses: true
		})
	);

	const handleAddToSearch = () => {
		history.push({
			pathname: '/media/search',
			state: { queryText: `channel:"${selected.name}"` }
		});
	};

	const handleCloseMenu = (_e) => {
		setAnchorEl(null);
	};

	const handleOpenMenu = (e, selected) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setSelected(selected);
	};

	const handleEdit = () => {
		setShowSourceSheet(true);
		setAnchorEl(null);
	};

	const renderOptionsCell = ({ data }) => {
		return (
			<div className="wrap">
				<IconButton aria-controls="row-menu" aria-haspopup="true" onClick={(e) => handleOpenMenu(e, data)}>
					<MoreVert />
				</IconButton>
			</div>
		);
	};

	const handleCloseSourceSheet = useCallback(() => {
		setShowSourceSheet(false);
		setSelected(null);
		onChange(
			createDataSource({
				getDatahubApi,
				requestPath: '/sources/v1',
				onSearchStart: handleSearchStart,
				onSearchComplete: handleSearchComplete,
				convertItems,
				filterInclusions: ['media_types'],
				useWhereClauses: true
			})
		);
	}, [setShowSourceSheet, setSelected, onChange]);

	const renderSourceSheet = () => (
		<Sheet isOpen={showSourceSheet} onClose={handleCloseSourceSheet}>
			<div className="sheet-content">
				<Source source_id={selected?.source_id} />
			</div>
		</Sheet>
	);

	const renderCreateSheet = () => (
		<Sheet isOpen={create} onClose={() => setCreate(false)}>
			<Content>
				<Source mediaTypes={mediaTypes?.map((mt) => ({ media_type_id: mt.value, name: mt.text }))} createMode />
			</Content>
		</Sheet>
	);

	const renderSourceCell = ({ data, value }) => {
		const overrideProfileImage = data.override_profile_image_url;
		return (
			<div className="wrap">
				<SourceLogo
					imageUrl={overrideProfileImage}
					sourceUrl={stringIsNullOrEmpty(overrideProfileImage) ? data.url : ''}
					sourceName={value}
				/>
			</div>
		);
	};

	const handleRowClick = useCallback(
		({ data }) => {
			setSelected(data);
			setShowSourceSheet(true);
		},
		[setSelected, setShowSourceSheet]
	);

	const renderGrid = () => {
		return (
			<DataGrid
				id="sources-table"
				height={gridHeight}
				showRowLines
				dataSource={dataSource}
				hoverStateEnabled
				showColumnLines={false}
				onRowClick={handleRowClick}
				showBorders
				remoteOperations
			>
				<Scrolling mode="infinite" />

				<FilterRow visible applyFilter="auto" />

				<HeaderFilter visible allowSearch />

				<Column
					caption="Channel"
					dataField="name"
					cssClass="cell cell--pad clickable"
					cellRender={renderSourceCell}
					allowHeaderFiltering={false}
				>
					<HeaderFilter allowSearch />
				</Column>

				<Column
					caption="Media Type"
					dataField="media_type"
					dataType="string"
					cssClass="cell cell--pad clickable"
					allowFiltering
					allowSorting
					allowSearch={false}
					cellRender={renderPlaintextCell}
				>
					<HeaderFilter allowSearch dataSource={mediaTypeFilters} />
				</Column>

				<Column
					dataField="id"
					width={60}
					cssClass="cell cell--center"
					alignment="center"
					caption=""
					cellRender={renderOptionsCell}
					allowFiltering={false}
					allowSearch={false}
					allowSorting={false}
					ignoreCellClick={true}
				/>

				<RemoteOperations sorting filtering />
			</DataGrid>
		);
	};

	const renderMenu = () => {
		return (
			<Menu id="row-menu" anchorEl={anchorEl} keepMounted open={!isNullOrUndefined(anchorEl)} onClose={handleCloseMenu}>
				<MenuItem onClick={handleAddToSearch}>
					<Typography>Add to Search</Typography>
				</MenuItem>
				<MenuItem onClick={handleEdit}>
					<Typography>Edit</Typography>
				</MenuItem>
			</Menu>
		);
	};

	return (
		<>
			<Content>
				<Header header="Channels">
					<Button fab={<Add />} variant="primary" onClick={() => setCreate(true)} aria-label="Add Channel" disabled={isLoading} />
				</Header>
				<Grid container>
					<Grid item>{renderGrid()}</Grid>
				</Grid>
			</Content>
			{renderSourceSheet()}
			{renderCreateSheet()}
			{renderMenu()}
		</>
	);
};

export default Sources;
